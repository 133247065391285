import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'

@Injectable()
export class JoiningBonusListPageService {
  getListDataUrl = environment.SERVER + '/api/joiningBonus';
  getJoiningBonusInfoUrl = environment.SERVER + '/api/joiningBonus';
  updateJoiningBonus = environment.SERVER + '/api/joiningBonus';

  constructor(private http: HttpClient) { }

   getListData(limit, offset, status) {
    const queries = [];
    let string = '';
    if (limit) {
        string = 'limit=' + limit;
        queries.push(string);
    }
    string = 'offset=' + offset || '0';
    queries.push(string);

    if (status && status != "All") {
      string = 'status=' + status.toLowerCase();
      queries.push(string);
    }
    queries.push('type=B2B')
    queries.push('type=B2C')
    const queryParams = queries.length > 0 ? '?' + queries.join('&') : '';
    return this.http.get<any>(this.getListDataUrl + queryParams);
  }

  getJoiningBonusInfo(joiningBonusId) {
    return this.http.get<any>(this.getJoiningBonusInfoUrl + '/' + joiningBonusId);
  }

  deactivate(joiningBonusId) {
    var payload = {
        'body' : {
            'isActive': false
        }
    }
    return this.http.put<any>(this.updateJoiningBonus + '/' + joiningBonusId, payload);
  }
}
