import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'

@Injectable()
export class RejoiningBonusListPageService {
  getListDataUrl = environment.SERVER + '/api/rejoiningBonus';
  getRejoiningBonusInfoUrl = environment.SERVER + '/api/rejoiningBonus';
  updateRejoiningBonus = environment.SERVER + '/api/rejoiningBonus';

  constructor(private http: HttpClient) { }

  getListData(limit, offset, status) {
    const queries = [];
    let string = '';
    if (limit) {
        string = 'limit=' + limit;
        queries.push(string);
    }
    string = 'offset=' + offset || '0';
    queries.push(string);
    if (status && status != "All") {
      string = 'status=' + status.toLowerCase();
      queries.push(string);
    }

    const queryParams = queries.length > 0 ? '?' + queries.join('&') : '';
    return this.http.get<any>(this.getListDataUrl + queryParams);
  }

  getRejoiningBonusInfo(rejoiningBonusId) {
    return this.http.get<any>(this.getRejoiningBonusInfoUrl + '/' + rejoiningBonusId);
  }

  deactivate(rejoiningBonusId) {
    var payload = {
      "userId": localStorage.getItem('userId'),
      'body': {
        'isActive': false
      }
    }
    return this.http.put<any>(this.updateRejoiningBonus + '/' + rejoiningBonusId, payload);
  }
}
