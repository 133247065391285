import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastMessage } from '../toast-message/toast-message.service';
import { JoiningBonusCreatePageService } from './joining-bonus-create-page.service';
@Component({
  selector: 'joining-bonus-create-page',
  template: require('./joining-bonus-create-page.component.html'),
  providers: [ToastMessage, JoiningBonusCreatePageService]
})

export class JoiningBonusCreatePageComponent implements OnInit {
  cityList = [];
  cityMap = {};
  selectedCity: string = null;
  selectedCityId: string = null;
  serviceList: string[] = [];
  selectedServices: string[] = [];
  servicesFetched = [];
  stepper: string[] = ['general'];
  filledForm: string[] = [];
  joiningBonusName: string;
  stepperValue = 0;
  joiningBonusData: any = {};
  joiningBonusOfferPage: boolean = false
  eligibleForAllServices: string = 'false';
  priority: number = 0;
  campaignType: string = 'B2C';

  ngOnInit() {
    this.getCities();
  }

  constructor(
    private router: Router,
    private _toastMessage: ToastMessage,
    private _joiningBonusCreatePageService: JoiningBonusCreatePageService
  ) { }

  logout() {
    document.cookie = ' ';
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    this._toastMessage.openSnackBarSuccess('You have been logged out');
    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    this.router.navigate(['/']);
  }

  backToList() {
    this.router.navigate(['/joiningBonus/list']);
  }

  getCities() {
    this._joiningBonusCreatePageService.getCityList().subscribe(res => {
      const cityList = [];
      var cities = res.data.cities;
      for (const city in cities) {
        if (cities[city]) {
          const name = cities[city]['displayName'];
          this.cityMap[name] = cities[city]['_id'];
          cityList.push(name);
        }
      }
      this.cityList = cityList;
    }, () => {
      this._toastMessage.openSnackBarError('Unable to fetch cities.');
    });
  }

  formatDate = (timestamp: any) => {
    const dateObj = new Date(timestamp);
    return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
  }

  cityChanged() {
    if (this.selectedCity == null) {
      this.serviceList = []
      this.selectedServices = []
    }
    this.selectedCityId = this.cityMap[this.selectedCity];
    this.fetchServices();
  }

  serviceEligibilityChanged(event) {
    this.eligibleForAllServices = event.value;
    this.priority = 0;
    this.selectedServices = [];
  }

  campaignTypeSelected(event){
    this.campaignType = event.value; 
  }

  fetchServices() {
    this.serviceList = [];
    this.servicesFetched = [];
    if (this.selectedCity) {
      const cityId = this.cityMap[this.selectedCity];
      this._joiningBonusCreatePageService.getServicesList(cityId).subscribe(res => {
        this.servicesFetched.push(...res.data);
        const finalServices = [];
        for (const service in this.servicesFetched) {
          if (service) {
            const serviceName = this.servicesFetched[service] && this.servicesFetched[service]['service']
              && this.servicesFetched[service]['service']['name'];
            finalServices.push(serviceName);
          }
        }
        this.serviceList = finalServices;
      }, () => {
        this._toastMessage.openSnackBarError('Unable to fetch services.');
      });
    }
  }

  stepperIncrement(num) {
    this.stepperValue = num;
    if (this.stepperValue === 100) {
      this._toastMessage.openSnackBarSuccess('You\'re all done!');
    }
  }

  resetGeneralDetails() {
    this.joiningBonusName = null;
    this.selectedCity = null;
    this.selectedCityId = null;
    this.selectedServices = [];
    this.priority = 0;
    this.campaignType = 'B2C';
  }

  onSubmitGenaralDetails = () => {
    let selectedServiceDetails = []
    this.servicesFetched.forEach((service) => {
      if (service) {
        const serviceName = service.service && service.service.name;
        if (this.selectedServices.includes(serviceName)) {
          selectedServiceDetails.push({ _id: service._id, name: serviceName })
        }
      }
    })

    this.joiningBonusData.name = this.joiningBonusName
    this.joiningBonusData.city = this.selectedCity
    this.joiningBonusData.cityId = this.selectedCityId
    this.joiningBonusData.serviceDetails = selectedServiceDetails
    this.joiningBonusData.priority = this.priority
    this.joiningBonusData.type = this.campaignType

    this._joiningBonusCreatePageService.validate(this.joiningBonusData).subscribe(res => {
      this._toastMessage.openSnackBarSuccess('General details submitted');
      this.stepper = [];
      if (this.filledForm.indexOf('general') < 0) {
        this.filledForm.push('general');
      }
      this.stepperIncrement(20);
      this._toastMessage.openSnackBarSuccess('You can now click Expand All and Collapse All on the bottom right for this panel');
      this.joiningBonusOfferPage = true
    },
      (err) => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this._toastMessage.openSnackBarError(err.error.error.message);
        } else {
          this._toastMessage.openSnackBarError('Something went wrong, please try again.');
        }
      })
  }

  submitGeneralDetails() {
    if (!this.joiningBonusName || this.joiningBonusName.trim().length === 0) {
      this._toastMessage.openSnackBarError('Please enter a name for the joining bonus');
      return
    }
    if (this.selectedCity === null) {
      this._toastMessage.openSnackBarError('Please select the city that this joining bonus will run in');
      return
    }
    if (this.selectedServices.length === 0 && this.eligibleForAllServices !== 'true') {
      this._toastMessage.openSnackBarError('Please select the service that this joining bonus will apply for');
      return
    }
    if (this.selectedServices.length > 0 && this.eligibleForAllServices !== 'true' && this.priority <= 0) {
      this._toastMessage.openSnackBarError('Please enter a priority greater than 0');
      return
    }
    if (this.eligibleForAllServices === 'true' && this.priority !== 0) {
      this._toastMessage.openSnackBarError('Please specify services for entering priority');
      return
    } 
    this.joiningBonusName = this.joiningBonusName.trim();
    this.onSubmitGenaralDetails();
  }

  expansionPanelOpen(evt, stage) {
    if (this.stepper.indexOf(stage) < 0) {
      this.stepper.push(stage);
    }
  }

  expansionPanelClose(evt, stage) {
    if (this.stepper.indexOf(stage) > -1) {
      this.stepper.splice(this.stepper.indexOf(stage), 1);
    }
  }

  expandAll() {
    this.stepper = ['type', 'general'];
  }

  collapseAll() {
    this.stepper = [];
  }
}
