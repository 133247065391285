import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastMessage } from '../toast-message/toast-message.service';
import { RejoiningBonusFormService } from './rejoining-bonus-form.service';
import { ConfirmationDialogueService } from '../confirmation-dialogue/confirmation-dialogue.service';

@Component({
  selector: 'rejoining-bonus-form',
  template: require('./rejoining-bonus-form.component.html'),
  providers: [ToastMessage, RejoiningBonusFormService]
})

export class RejoiningBonusFormComponent implements OnInit {
  private readonly rapidoWallet = 'RAPIDO_WALLET';

  eligibleCitiesFormControl = new FormControl('', [
    Validators.required
  ]);
  eligibleServicesFormControl = new FormControl('', [
    Validators.required
  ]);

  @Output() incrementStepper: EventEmitter<any> = new EventEmitter();
  @Output() expandAll: EventEmitter<any> = new EventEmitter(true);
  @Output() collapseAll: EventEmitter<any> = new EventEmitter(true);
  @Input() rejoiningBonusData: any;

  termsAndConditions: any;
  stepper: string[] = ['how'];
  enabledStepper: string[] = ['how'];
  filledForm: string[] = [];
  errMsg = 'Something went wrong';
  noOfRideBonus = 0;
  rideBonusArray = [];
  campaignName: string;
  walletList: string[] = [this.rapidoWallet];

  constructor(
    private router: Router,
    private _toastMessage: ToastMessage,
    private _rejoiningBonusFormService: RejoiningBonusFormService,
    private _confirmationDialogService: ConfirmationDialogueService) { }

  ngOnInit() { }

  validateRideBonusStages() {
    let checkedRideBonus = 0;
    if (this.noOfRideBonus > 0) {
      for (let i = 0; i < this.rideBonusArray.length; i++) {
        const minimumRides = this.rideBonusArray[i].minRide;
        const orderDays = this.rideBonusArray[i].orderDays;
        const incentiveAmount = this.rideBonusArray[i].incentiveAmount;
        if (this.isEmpty(minimumRides) || minimumRides < 1) {
          this.errMsg = 'Please select minimum of 1 ride limit for the ride bonus #' + (i + 1);
          this._toastMessage.openSnackBarError(this.errMsg);
          throw new Error(this.errMsg);
        } else if (this.isEmpty(orderDays) || orderDays < 1) {
          this.errMsg = 'Please select minimum of 1 order day(s) limit for the ride bonus #' + (i + 1);
          this._toastMessage.openSnackBarError(this.errMsg);
          throw new Error(this.errMsg);
        } else if (this.isEmpty(incentiveAmount) || incentiveAmount < 1) {
          this.errMsg = 'Please enter minimum of 1 as incentive amount for ride bonus #' + (i + 1);
          this._toastMessage.openSnackBarError(this.errMsg);
          throw new Error(this.errMsg);
        } else if (!this.rideBonusArray[i].incentiveType) {
          this.errMsg = 'Please fill in the mode of payments for the captains for ride bonus #' + (i + 1);
          this._toastMessage.openSnackBarError(this.errMsg);
          throw new Error(this.errMsg);
        } else {
          checkedRideBonus++;
        }
      }
    }
    return checkedRideBonus;
  }

  addRideBonus() {
    if (this.noOfRideBonus === 4) {
      this._toastMessage.openSnackBarError("Cannot add more than 4 ride bonus rules");
      return
    }
    const validatedUserActStages = this.validateRideBonusStages();
    if (validatedUserActStages === this.noOfRideBonus) {
      this.noOfRideBonus++;
      this.rideBonusArray.push({
        orderDays: '',
        minRide: '',
        incentiveType: this.rapidoWallet,
        incentiveAmount: ''
      });
    }
  }

  removeRideBonus(index) {
    const x = confirm('Are you sure you want to remove this ride bonus?');
    if (x) {
      this.noOfRideBonus--;
      this.rideBonusArray.splice(index, 1);
    }
  }

  isEmpty(value) {
    return (value === null || value.length === 0);
  }

  submitRules() {
    if (this.noOfRideBonus === 0) {
      this._toastMessage.openSnackBarError('Please enter at least one stage');
    } else {
      var checkedRideBonus: any = 0;
      if (this.noOfRideBonus > 0) {
        checkedRideBonus = this.validateRideBonusStages();
      }
      if (checkedRideBonus === this.rideBonusArray.length) {
        this.createFinalArray();
      }
    }
  }

  createFinalArray() {
    let rules: any = {};
    let finalOrderStates = 0;
    const rideBonuses = []
    if (this.rideBonusArray && this.rideBonusArray.length > 0) {
      for (const rideBonus in this.rideBonusArray) {
        if (this.rideBonusArray[rideBonus]) {
          const info = this.rideBonusArray[rideBonus];
          rideBonuses.push({
            "minRide": info.minRide,
            "maxDays": info.orderDays,
            "incentiveAmount": info.incentiveAmount,
            "walletType": info.incentiveType
          });
          finalOrderStates++;
        }
      }
    }
    rules.rideBonusRules = rideBonuses

    this.updateRules(rules);
  }

  updateRules(rules) {
    this.rejoiningBonusData.rideBonusRules = rules.rideBonusRules
    this._rejoiningBonusFormService.validate(this.rejoiningBonusData).subscribe(() => {
      this.stepper = ['tncs'];
      if (this.enabledStepper.indexOf('tncs') < 0) {
        this.enabledStepper.push('tncs');
      }
      this.incrementStepper.emit(50);
      this._toastMessage.openSnackBarSuccess('Successfully submitted rules')
    },
      err => {
        console.log('err (rules) : ', err);
        this._toastMessage.openSnackBarError(err && err.error && err.error.error && err.error.error.message ?
          err.error.error.message : 'Something went wrong');
      });
  }

  resetRules() {
    this.rideBonusArray = [];
    this.noOfRideBonus = 0;
  }

  onSubmitTncs() {
    const tncs: any = this.termsAndConditions;
    if (!tncs || tncs.trim().length === 0) {
      return this._toastMessage.openSnackBarError('Please enter a terms and condtions of the campaign.');

    }
    this._confirmationDialogService.confirm('Caution', `Are you sure you want to go live with ${this.rejoiningBonusData.name}?`)
      .then((confirmed) => {
        if (confirmed) {
          const termsAndConditions: any = tncs.split(';').map((term: any) => term.trim()).filter((term: any) => term);
          this.rejoiningBonusData.tncs = termsAndConditions

          this._rejoiningBonusFormService.create(this.rejoiningBonusData).subscribe(() => {
            this.stepper = [];
            this.incrementStepper.emit(100)
            setTimeout(() => {
              this.router.navigate(['/rejoiningBonus/list']);
            }, 1000);
          },
            (err: any) => {
              if (err && err.error && err.error.error && err.error.error.message) {
                this._toastMessage.openSnackBarError(err.error.error.message);
              } else {
                this._toastMessage.openSnackBarError('Something went wrong, please try again.');
              }
            });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  resetTncs() {
    this.termsAndConditions = []
  }

  expandAllAccordians() {
    this.expandAll.emit();
    this.stepper = [];
    this.stepper = this.enabledStepper;
  }

  collapseAllAccordians() {
    this.stepper = [];
    this.collapseAll.emit();
  }

  expansionPanelOpen(evt, stage) {
    if (this.stepper.indexOf(stage) < 0) {
      this.stepper = [stage];
    }
  }

  expansionPanelClose(evt, stage) {
    const tempEnabled = JSON.stringify(this.enabledStepper);
    if (this.stepper.indexOf(stage) > -1) {
      this.stepper.splice(this.stepper.indexOf(stage), 1);
    }
    this.enabledStepper = JSON.parse(tempEnabled);
  }
}