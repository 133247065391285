import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ToastMessage } from '../toast-message/toast-message.service';
import { CampaignPerformanceService } from '../campaign-performance/campaign-performance.service';
import { DatePipe } from '@angular/common';
import { TableForPerformanceComponent } from '../table-for-performance/table-for-performance.component';
import { CampaignListPageService } from '../campaign-list-page/campaign-list-page.service'

@Component({
  selector: 'app-campaign-performance',
  template: require('./campaign-performance.component.html'),
  providers: [ToastMessage, CampaignPerformanceService, TableForPerformanceComponent]
})
export class CampaignPerformanceComponent implements OnInit {

  public campaignId: any;
  public campaignInfo: any;
  public startDate: Date;
  public endDate: Date;
  public maxDate: Date = new Date();
  public minToDate: Date = new Date();
  public dataSource = [];
  public campaignName: string;
  public citiesAssociated = [];
  public displayedColumns: string[] =
            ['type', 'oldUsers', 'newUsers', 'totalMoneySpent', 'oldUsersMoneySpent', 'newUsersMoneySpent', 'avgMoneySpent'];
  public selectedCities = [];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private _toastMessage: ToastMessage,
    private datePipe: DatePipe,
    private _campaignPerformanceService: CampaignPerformanceService,
    private campaignListPageService: CampaignListPageService) { }

  ngOnInit() {
    this.campaignId = this.route.snapshot.queryParamMap.get('id');
    this.startDate = new Date(parseFloat(this.route.snapshot.queryParamMap.get('startDate')));
    this.endDate = new Date(parseFloat(this.route.snapshot.queryParamMap.get('endDate')));
    if (this.campaignId && this.startDate && this.endDate) {
      this.verifyDates();
      this.campaignListPageService.getCampaignInfo(this.campaignId).subscribe(result => {
        if (result && result.body && result.body.result && result.body.result.cities) {
          this.citiesAssociated = result.body.result.cities;
        } else {
          this._toastMessage.openSnackBarError('No cities found for this campaign.');
        }
      }, error => {
        this._toastMessage.openSnackBarError('Unable to fetch cities for this campaign. Please try again.');
      });
    } else {
      this._toastMessage.openSnackBarError('Enter start and end dates to continue');
    }
  }

  verifyDates() {
    if (!this.startDate) {
      this._toastMessage.openSnackBarError('Enter start date to continue');
      return;
    }
    if (!this.endDate) {
      this._toastMessage.openSnackBarError('Enter end date to continue');
      return;
    }
    if (this.startDate > this.endDate) {
      this._toastMessage.openSnackBarError('Start date cannot be greater than end date.');
      return;
    }
    this.getPerformanceData();
  }

  getThisMonth(type) {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime();
    switch (type) {
      case 'start' : return firstDay;
      case 'end' : return lastDay;
    }
  }

  getPerformanceData() {
    let citiesToFilter = null;
    const startDate = this.startDate ? new Date(this.startDate).getTime() : this.getThisMonth('start');
    const endDate = this.endDate ? new Date(this.endDate).getTime() : this.getThisMonth('end');
    if (this.selectedCities && this.selectedCities.length && this.selectedCities.length !== this.citiesAssociated.length) {
      citiesToFilter = this.selectedCities;
    }
    this._campaignPerformanceService.getPerformanceData(this.campaignId, startDate, endDate, citiesToFilter).subscribe(res => {
      this.campaignInfo = res.body.result;
      if (this.campaignInfo && this.campaignInfo.startDate && this.campaignInfo.endDate) {
        this.campaignName = this.getProperDate(parseFloat(this.campaignInfo.startDate)) + ' - ' +
                                this.getProperDate(parseFloat(this.campaignInfo.endDate))
      }
      this.dataSource = this.campaignInfo.ruleData;
      this._toastMessage.openSnackBarSuccess('Successfully fetched performance data!');
    },
    err => {
      this._toastMessage.openSnackBarError(err.message);
      return;
    });
  }

  getProperDate(timeStamp) {
    const today = new Date(timeStamp);
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;

    const yyyy: any = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    const dateInfo = dd + '-' + mm + '-' + yyyy;
    return (dateInfo);
  }

  getRules(data) {
    const dataSource = [];
    this.dataSource = dataSource;
  }

  reloadDataSources() {
  }

  toggleRows(id) {
    const x = document.getElementById(id);
    if (!x) {
      this._toastMessage.openSnackBarError('No sub-rules found!')
    } else {
      x.style.display = x.style.display === 'none' ? 'block' : 'none';
    }
  }

  logout(){
    document.cookie = ' ';
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    this._toastMessage.openSnackBarSuccess('You have been logged out');
    document.cookie = 'token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    this.router.navigate(['/']);
  }

  backToList() {
    this.router.navigate(['/campaign/list']);
  }

}
