import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'

@Injectable()
export class RejoiningBonusFormService {
  validateAPI = environment.SERVER + '/api/rejoiningBonus/validate';
  createAPI = environment.SERVER + '/api/rejoiningBonus';
  constructor(private http: HttpClient) { }

  validate(data) {
    const payload = {
      'body': data
    };
    return this.http.post<any>(this.validateAPI, payload);
  }

  create(data) {
    const payload = {
      'body': data
    };
    return this.http.post<any>(this.createAPI, payload);
  }
}
