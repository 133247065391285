import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';


import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'



const httpOptions = {
  headers: new HttpHeaders({
    'user': '{"_id":"'+localStorage.getItem("userId")+'"}'
  })
};

export interface Config {
  heroesUrl: string;
  textfile: string;
}

@Injectable()
export class SigninService {
  
  loginUrl =  environment.SERVER+'/api/login';
  userUrl =   environment.SERVER + '/api/getUser';
  rolesUrl =  environment.SERVER + '/api/get/UserRoles';
  userAccessUrl = environment.SERVER + '/api/auth/userAccess';

  constructor(private http: HttpClient) {
}


  getLoginResponse(data): Observable<HttpResponse<any>> {
    return this.http.post<any>(
        this.loginUrl,data, {observe:'response'}
    );
  }
  getUserAccessToken(token) {
    return this.http.post(this.userAccessUrl, {"accessToken": token});
  }
  getUserInfo(email){
    return this.http.post<any>(this.userUrl,{"email":email});
  }
  getUserRoles(id){
    return this.http.post<any>(this.rolesUrl,{"userId": id});
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  makeIntentionalError() {
    return this.http.get('not/a/real/url')
      .pipe(
        catchError(this.handleError)
      );
  }

}
