import { isNullOrUndefined } from 'util';

// The list of file replacements can be found in `angular.json`.
const env: any = window['__env'] || {};
export const environment = {
  production: isNullOrUndefined(env.production) ? true : env.production,
  googleAccountKey: '274768070460-hh0qf89606tv1u7sappcjmdf34aiqb33.apps.googleusercontent.com',
  NODE_ENV: isNullOrUndefined(env.NODE_ENV) ? 'dev' : env.NODE_ENV,
  SERVER: isNullOrUndefined(env.SERVER) ? 'http://localhost:8081' : env.SERVER,
};
