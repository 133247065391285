import { Component, OnInit, Input } from '@angular/core';
import { ToastMessage } from '../toast-message/toast-message.service';

@Component({
  selector: '[table-for-performance]',
  template: require('./table-for-performance.component.html'),
  providers: [ToastMessage]
})
export class TableForPerformanceComponent implements OnInit {

  constructor(private _toastMessage : ToastMessage) { }

  @Input() data: any;

  ngOnInit() {
  }

  toggleRows(id) {
    var x = document.getElementById(id);
    if(!x) {
      this._toastMessage.openSnackBarError("No sub-rules found!")
    }
    else x.style.display = x.style.display === "none" ? "block" : "none";
  }

}
