import { Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-campaign-referral-metric-table',
  template: require('./campaign-referral-metric-table.component.html')
})
export class CampaignReferralMetricTableComponent  implements OnInit {
  @Input() orderUsers: any;
  @Input() registrationUsers: any;
  @Input() activationUsers: any;
  @Input() rules: any;

  collapse = false;
  users: any;
  totalUsers = 0;
  completedUsers = [];
  usersWithCompletedRules = 0;
  metricTableData: any;
  isUserExist: boolean;

  async ngOnInit() {
    this.totalUsers = this.registrationUsers.length + this.activationUsers.length + this.orderUsers.length;
    this.metricTableData = [];
    if (this.rules) {
      this.rules.map((eachRule: any) => {
        let users = [];
        if (eachRule.ruleType === 'REGISTRATION') {
          users = this.registrationUsers;
          this.completedUsers = [...this.completedUsers, ...this.registrationUsers];
          this.usersWithCompletedRules += this.registrationUsers.length;
        } else if (eachRule.ruleType === 'ORDERS') {
          for (const user in this.orderUsers) {
            if (this.orderUsers[user]) {
              if (this.orderUsers[user].matchedIncentive && eachRule.maxValue && (this.orderUsers[user].matchedIncentive >= eachRule.maxValue)) {
                users.push(this.orderUsers[user])
                this.completedUsers = [...this.completedUsers, this.orderUsers[user]];
                this.usersWithCompletedRules++;
              }
            }
          }
        } else {
          users = this.activationUsers;
          this.usersWithCompletedRules += this.activationUsers.length;
          this.completedUsers = [...this.completedUsers, ...this.activationUsers];
        }
        const data = Object.assign({}, { ...eachRule, users});
        this.metricTableData.push(data);
      });
    }
    const incomplete = this.fixUnunsedUsers();
    if (incomplete && incomplete.length) {
      this.metricTableData.push({
      incentiveAmountForReferred: 0,
      incentiveAmountForReferrer: 0,
      ruleType: 'INCOMPLETE RULES',
      users: this.fixUnunsedUsers()
    });
    }
  }

  toggleCollapse = (rule: any) => {
    return rule.isCollapsed = !rule.isCollapsed;
  }

  getCollapseIdentity = (rule: any) => {
    if (rule.isCollapsed) {
      return '-';
    }
    return '+';
  }

  getUsers (ruleType: any) {
    if (ruleType === 'REGISTRATION') {
      this.users = this.registrationUsers;
    } else if (ruleType === 'ORDERS') {
      this.users = this.orderUsers;
    } else {
      this.users = this.activationUsers;
    }
  }

  getRuleTypeUsersLength = (ruleType: any) => {
    if (ruleType === 'REGISTRATION') {
      return this.registrationUsers.length;
    } else if (ruleType === 'ORDERS') {
      return this.orderUsers.length;
    }
    return this.activationUsers.length;
  }

  getRuleUsersLength = (users) => {
    if (typeof(users) === 'number') {
      return users;
    } else {
      return users.length;
    }
  }

  getTotalMoneySpent = (rule: any) => {
    const userLength = rule.users ? this.getRuleUsersLength(rule.users) : 0;
    return (rule.incentiveAmountForReferred * userLength) + (rule.incentiveAmountForReferrer * userLength);
  }

  getMoneyReceiverByOldUser = (rule: any) => {
    const userLength = rule.users ? this.getRuleUsersLength(rule.users) : 0;
    if(rule.subRuleType === 'CUMULATIVE') {
      return userLength > rule.maxValue? rule.incentiveAmountForReferrer : 0;
    }
    return rule.incentiveAmountForReferrer * userLength;
  }

  getMoneySpent = (rule: any) => {
    const userLength = rule.users ? this.getRuleUsersLength(rule.users) : 0;
    return rule.incentiveAmountForReferred * userLength;
  }

  getNoUserStatus = (rule: any) => {
    return rule.isCollapsed && rule.users && rule.users.length === 0;
  }

  getNoUsersStatus = (rule: any) => {
    return rule.isCollapsed && ((!rule.users) || (rule.users && !rule.users.length));
  }

  getMoneySpentPerNewUser = (rule: any) => {
    if ( rule.users && rule.users.length > 0 ) {
      return rule.incentiveAmountForReferred;
    }
    return 0;
  }

  fixUnunsedUsers() {
    if (this.totalUsers !== this.usersWithCompletedRules) {
      const allUsers = [...this.registrationUsers, ...this.activationUsers, ...this.orderUsers];
      for (const users in allUsers) {
        if (allUsers[users] && allUsers[users].mobile) {
          for (const user in this.completedUsers) {
            if (this.completedUsers[user] && this.completedUsers[user].mobile
                && this.completedUsers[user].mobile === allUsers[users].mobile) {
                  const index = parseInt(users, 10);
                  allUsers.splice(index, 1);
            }
          }
        }
      }
      return allUsers;
    }
  }

  showDetailedRule(rule) {
    if (rule && rule.ruleType) {
      if (rule.ruleType === 'ORDERS') {
        return 'Complete ' + rule.maxValue + ' rides in ' + rule.expiryInDays + ' days';
      } else if (rule.ruleType === 'ACTIVATION') {
        return 'Activation stage';
      } else if (rule.ruleType === 'REGISTRATION') {
        return 'Registration stage';
      }
    } else {
      return 'N/A';
    }
  }
}
