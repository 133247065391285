import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxLoadingModule } from 'ngx-loading';

import { AppComponent } from '../../src/app/app.component';
import { AppRoutingModule } from './app-routing.module';


import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule } from '@angular/material';
import { HotkeyModule } from 'angular2-hotkeys';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClarityModule } from '@clr/angular';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material';
import { MatRadioModule } from '@angular/material/radio';

import { ToastSnackbar } from './toast-message/toast-message.service';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { SigninComponent } from './signin/signin.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import {MatSortModule} from '@angular/material/sort';
import { CouponsPageComponent } from './coupons-page/coupons-page.component';
import { CampaignCreatePageComponent } from './campaign-create-page/campaign-create-page.component';
import { CampaignFormComponent } from './campaign-form/campaign-form.component';

import "froala-editor/js/froala_editor.pkgd.min.js";

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { CampaignListPageComponent } from './campaign-list-page/campaign-list-page.component';
import { CampaignListPageService } from './campaign-list-page/campaign-list-page.service'
import { CampaignPerformanceComponent } from './campaign-performance/campaign-performance.component';
import { PerformanceTableComponent } from './performance-table/performance-table.component';
// import { CampaignSearchComponent } from './campaign-search/campaign-search.component';
// import { CampaignSearchMetricCardComponent } from './campaign-search/campaign-search-metric-card.component';
import { CampaignReferralSearchComponent } from './campaign-referral-search/campaign-referral-search.component';
import { CampaignMetricCardComponent } from './campaign-metric-card/campaign-metric-card.component';
import { CampaignReferralMetricTableComponent } from './campaign-referral-metric-table/campaign-referral-metric-table.component';
import { ReferralSearchComponent } from './referral-search/referral-search.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/loader.service';
import { AccessRightsService } from './access-rights.service';
import { HttpIncerceptorService } from './httpinterceptor.service';
import { DialogComponent } from './dialog/dialog.component';
import { JoiningBonusCreatePageComponent } from './joining-bonus-create-page/joining-bonus-create-page.component';
import { JoiningBonusListPageComponent } from './joining-bonus-list-page/joining-bonus-list-page.component';
import { JoiningBonusFormComponent } from './joining-bonus-form/joining-bonus-form.component';
import { RejoiningBonusCreatePageComponent } from './rejoining-bonus-create-page/rejoining-bonus-create-page.component';
import { RejoiningBonusListPageComponent } from './rejoining-bonus-list-page/rejoining-bonus-list-page.component';
import { RejoiningBonusFormComponent } from './rejoining-bonus-form/rejoining-bonus-form.component';
import { ConfirmationDialogueComponent } from './confirmation-dialogue/confirmation-dialogue.component';
import { ConfirmationDialogueService } from './confirmation-dialogue/confirmation-dialogue.service';


@NgModule({
  declarations: [
    AppComponent,
    ToastSnackbar,
    SigninComponent,
    CouponsPageComponent,
    CampaignCreatePageComponent,
    CampaignFormComponent,
    CampaignListPageComponent,
    JoiningBonusCreatePageComponent,
    JoiningBonusListPageComponent,
    JoiningBonusFormComponent,
    RejoiningBonusCreatePageComponent,
    RejoiningBonusListPageComponent,
    RejoiningBonusFormComponent,
    CampaignPerformanceComponent,
    PerformanceTableComponent,
    // CampaignSearchMetricCardComponent,
    CampaignReferralSearchComponent,
    CampaignMetricCardComponent,
    CampaignReferralMetricTableComponent,
    ReferralSearchComponent,
    LoaderComponent,
    DialogComponent,
    ConfirmationDialogueComponent
  ],
  imports: [
    BrowserModule,
    NgxLoadingModule.forRoot({}),
    BrowserAnimationsModule,
    MatSortModule,
    HotkeyModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatGridListModule,
    MatCardModule,
    MatDialogModule,
    MatSnackBarModule,
    MatMenuModule,
    MatBadgeModule,
    MatTooltipModule,
    MatTreeModule,
    MatCheckboxModule,
    MatSelectModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    NgSelectModule,

    ScrollDispatchModule,

    MatTableModule,
    NgbModule.forRoot(),
    //  ImageUploadModule.forRoot()
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot()
  ],
  entryComponents: [
   ToastSnackbar, DialogComponent, ConfirmationDialogueComponent
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpIncerceptorService,
    multi: true
  }, AccessRightsService, LoaderService,
  CampaignListPageService, ConfirmationDialogueService],
  bootstrap: [AppComponent]
})
export class AppModule { }
