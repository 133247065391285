import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';


import { throwError } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'


const httpOptions = {
    headers: new HttpHeaders({
      'x-consumer-username' : '5c9b45b6b50ab0456be5af2c'
    })
};
@Injectable()
export class CampaignReferralSearchService {
  constructor(private http: HttpClient) {}

  userActivity = environment.SERVER + '/api/getUserActivity';

  getUserActivity(queryParams: any) {
    const { mobile, role, startDate, endDate } = queryParams;
    const queryStrings = `?mobile=${mobile}&role=${role}&startDate=${startDate}&endDate=${endDate}`;

    return this.http.get<any>(`${this.userActivity}${queryStrings}`);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError('Something bad happened; please try again later.');
  }
}
