import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  options: string[];
  title: string;
  reason: string;
  type: string;
  message: string;
  errorMessage: string;
}

@Component({
  selector: 'app-dialog',
  template: require('./dialog.component.html')
})
export class DialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  public tableData: any;
  onNoClick(): void {
  }
  ngOnInit() {
    console.log('Getting data -', this.data);
    if (this.data && this.data['data'] && this.data['data'].data) {
      this.tableData = this.data['data'].data;
    }
  }
}
