import { Component, Output, EventEmitter, Input  } from '@angular/core';

@Component({
  selector: 'app-campaign-metric-card',
  template: require('./campaign-metric-card.component.html')
})

export class CampaignMetricCardComponent {
  @Input() metricValue: any;
  @Input() metricLabel: any;
  @Input() isMoneySpentMetricCard: boolean;

  @Output() clicked = new EventEmitter();

  emitClickEvent() {
      this.clicked.emit({success: true});
  }

  constructor() { }

}
