import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastMessage } from "../toast-message/toast-message.service";
import { RegistrationBonusConfig } from "./joining-bonus-form.model";
import { JoiningBonusFormService } from "./joining-bonus-form.service";
import { JoiningBonusCreatePageComponent } from "../joining-bonus-create-page/joining-bonus-create-page.component";

@Component({
  selector: "joining-bonus-form",
  template: require('./joining-bonus-form.component.html'),
  providers: [ToastMessage, JoiningBonusFormService],
})
export class JoiningBonusFormComponent implements OnInit {
  private readonly rapidoWallet = "RAPIDO_WALLET";
  private readonly walletRedeemBlock = "REDEEM_BLOCK";

  eligibleCitiesFormControl = new FormControl("", [Validators.required]);
  eligibleServicesFormControl = new FormControl("", [Validators.required]);

  @Output() incrementStepper: EventEmitter<any> = new EventEmitter();
  @Output() expandAll: EventEmitter<any> = new EventEmitter(true);
  @Output() collapseAll: EventEmitter<any> = new EventEmitter(true);
  @Input() joiningBonusData: any;

  startDate: Date;
  endDate: Date;
  termsAndConditions: any;
  minDate = new Date();
  stepper: string[] = ["when"];
  enabledStepper: string[] = ["when"];
  filledForm: string[] = [];
  errMsg = "Something went wrong";
  activationBonus: any = undefined;
  registrationBonus: any = undefined;
  noOfRideBonus = 0;
  rideBonusArray = [];
  campaignName: string;
  walletList: string[] = [this.rapidoWallet, this.walletRedeemBlock];
  registrationBonusConfig: RegistrationBonusConfig =
    new RegistrationBonusConfig(false);

  constructor(
    private router: Router,
    private _toastMessage: ToastMessage,
    private _joiningBonusFormService: JoiningBonusFormService,
    private _joiningBonusCreatePageComponent: JoiningBonusCreatePageComponent
  ) {}

  ngOnInit() {
    this._joiningBonusFormService.config().subscribe(
      (config: RegistrationBonusConfig) => {
        this.registrationBonusConfig = config;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  submitTimeline() {
    if (!this.startDate || !this.endDate) {
      this._toastMessage.openSnackBarError("Please enter both the dates.");
    } else if (this.startDate > this.endDate) {
      this._toastMessage.openSnackBarError(
        "The 'to' date cannot be before the 'from' date"
      );
    } else {
      const sendStartDate = new Date(this.startDate).getTime();
      const sendEndDate = new Date(this.endDate).getTime();

      this.joiningBonusData.startDate = sendStartDate;
      this.joiningBonusData.endDate = sendEndDate;

      this._joiningBonusFormService.validate(this.joiningBonusData).subscribe(
        () => {
          this.stepper = ["how"];
          if (this.enabledStepper.indexOf("how") < 0) {
            this.enabledStepper.push("how");
          }
          if (this.filledForm.indexOf("when") < 0) {
            this.filledForm.push("when");
          }
          this.incrementStepper.emit(50);
          this._toastMessage.openSnackBarSuccess(
            "Submitted Start date and End date"
          );
        },
        (err) => {
          console.log("err (when) : ", err);
          this._toastMessage.openSnackBarError(
            err && err.error && err.error.error && err.error.error.message
              ? err.error.error.message
              : "Something went wrong"
          );
        }
      );
    }
  }

  validateActivationBonus() {
    if (this.activationBonus) {
      if (this.isEmpty(this.activationBonus.incentiveAmount)) {
        this.errMsg = "Please enter incentive amount for activation bonus";
        this._toastMessage.openSnackBarError(this.errMsg);
        throw new Error(this.errMsg);
      } else if (!this.activationBonus.incentiveType) {
        this.errMsg =
          "Please fill in the activation bonus mode of payment for the captains for activation bonus";
        this._toastMessage.openSnackBarError(this.errMsg);
        throw new Error(this.errMsg);
      }
    }
  }

  validateRideBonusStages() {
    let checkedRideBonus = 0;
    if (this.noOfRideBonus > 0) {
      for (let i = 0; i < this.rideBonusArray.length; i++) {
        if (this.isEmpty(this.rideBonusArray[i].minRide)) {
          this.errMsg =
            "Please select ride limit for the ride bonus #" + (i + 1);
          this._toastMessage.openSnackBarError(this.errMsg);
          throw new Error(this.errMsg);
        } else if (this.isEmpty(this.rideBonusArray[i].orderDays)) {
          this.errMsg =
            "Please select order days limit for the ride bonus #" + (i + 1);
          this._toastMessage.openSnackBarError(this.errMsg);
          throw new Error(this.errMsg);
        } else if (this.isEmpty(this.rideBonusArray[i].incentiveAmount)) {
          this.errMsg =
            "Please enter incentive amount for ride bonus #" + (i + 1);
          this._toastMessage.openSnackBarError(this.errMsg);
          throw new Error(this.errMsg);
        } else if (!this.rideBonusArray[i].incentiveType) {
          this.errMsg =
            "Please fill in the mode of payments for the captains for ride bonus #" +
            (i + 1);
          this._toastMessage.openSnackBarError(this.errMsg);
          throw new Error(this.errMsg);
        } else {
          checkedRideBonus++;
        }
      }
    }
    return checkedRideBonus;
  }

  validateregistrationBonus() {
    if (this.registrationBonus) {
      if (this.isEmpty(this.registrationBonus.maxOnboardingHours)) {
        this.errMsg =
          "Please enter max onboarding duration for registration bonus";
        this._toastMessage.openSnackBarError(this.errMsg);
        throw new Error(this.errMsg);
      } else if (this.isEmpty(this.registrationBonus.incentiveAmount)) {
        this.errMsg = "Please enter incentive amount for registration bonus";
        this._toastMessage.openSnackBarError(this.errMsg);
        throw new Error(this.errMsg);
      } else if (!this.registrationBonus.incentiveType) {
        this.errMsg =
          "Please fill in the mode of payment for the captains for registration bonus";
        this._toastMessage.openSnackBarError(this.errMsg);
        throw new Error(this.errMsg);
      } else if (
        this.registrationBonus.incentiveType === this.walletRedeemBlock
      ) {
        if (this.isEmpty(this.registrationBonus.minRide)) {
          this.errMsg =
            "Please select ride limit for the registration bonus unblock condition";
          this._toastMessage.openSnackBarError(this.errMsg);
          throw new Error(this.errMsg);
        } else if (this.isEmpty(this.registrationBonus.orderDays)) {
          this.errMsg =
            "Please select order days limit for the registration bonus unblock condition";
          this._toastMessage.openSnackBarError(this.errMsg);
          throw new Error(this.errMsg);
        }
      }
    }
  }

  addActivationBonus() {
    this.activationBonus = {
      incentiveType: this.rapidoWallet,
      incentiveAmount: "",
    };
  }

  addRideBonus() {
    if (this.noOfRideBonus === 4) {
      this._toastMessage.openSnackBarError(
        "Cannot add more than 4 ride bonus rules"
      );
    }
    const validatedUserActStages = this.validateRideBonusStages();
    if (validatedUserActStages === this.noOfRideBonus) {
      this.noOfRideBonus++;
      this.rideBonusArray.push({
        orderDays: "",
        minRide: "",
        incentiveType: this.rapidoWallet,
        incentiveAmount: "",
      });
    }
  }

  addregistrationBonus() {
    this.registrationBonus = {
      maxOnboardingHours: 1,
      incentiveType: this.walletRedeemBlock,
      incentiveAmount: 0,
      orderDays: undefined,
      minRide: undefined,
    };
  }

  removeActivation() {
    const x = confirm("Are you sure you want to remove this activation bonus?");
    if (x) {
      this.activationBonus = undefined;
    }
  }

  removeRideBonus(index) {
    const x = confirm("Are you sure you want to remove this ride bonus?");
    if (x) {
      this.noOfRideBonus--;
      this.rideBonusArray.splice(index, 1);
    }
  }

  removeregistrationBonus() {
    const x = confirm(
      "Are you sure you want to remove this registration bonus?"
    );
    if (x) {
      this.registrationBonus = undefined;
    }
  }

  isEmpty(value) {
    return value === null || value === undefined || value.length === 0;
  }

  isRegistrationBonusEnabled() {
    return (
      (this.registrationBonusConfig.enabled &&
        this.joiningBonusData &&
        this.joiningBonusData.serviceDetails &&
        this.joiningBonusData.serviceDetails.length === 0 &&
        this.registrationBonusConfig.cities.includes(
          this.joiningBonusData.cityId
        )) === true
    );
  }

  submitRules() {
    if (
      !this.activationBonus &&
      this.noOfRideBonus === 0 &&
      !this.registrationBonus
    ) {
      this._toastMessage.openSnackBarError("Please enter at least one stage");
    } else {
      this.validateActivationBonus();
      this.validateregistrationBonus();
      var checkedUserAct: any = 0;
      if (this.noOfRideBonus > 0) {
        checkedUserAct = this.validateRideBonusStages();
      }
      if (checkedUserAct === this.rideBonusArray.length) {
        this.createFinalArray();
      }
    }
  }

  createFinalArray() {
    let rules: any = {};
    let activationBonusArray = [];
    let registrationBonusArray = [];
    let finalActStates = 0;
    let finalOrderStates = 0;
    if (this.activationBonus) {
      const info = this.activationBonus;
      activationBonusArray.push({
        incentiveAmount: info.incentiveAmount,
        walletType: info.incentiveType,
      });
    }

    const rideBonuses = [];
    if (this.rideBonusArray && this.rideBonusArray.length > 0) {
      for (const rideBonus in this.rideBonusArray) {
        if (this.rideBonusArray[rideBonus]) {
          const info = this.rideBonusArray[rideBonus];
          rideBonuses.push({
            minRide: info.minRide,
            maxDays: info.orderDays,
            incentiveAmount: info.incentiveAmount,
            walletType: info.incentiveType,
          });
          finalOrderStates++;
        }
      }
    }
    if (this.registrationBonus) {
      const info = this.registrationBonus;
      registrationBonusArray.push({
        maxOnboardingHours: info.maxOnboardingHours,
        incentiveAmount: info.incentiveAmount,
        walletType: info.incentiveType,
        minRide: info.minRide,
        maxDays: info.orderDays,
      });
      finalActStates++;
    }
    rules.activationBonusRules = activationBonusArray;
    rules.rideBonusRules = rideBonuses;
    rules.registrationBonusRules = registrationBonusArray;

    this.updateRules(rules);
  }

  updateRules(rules) {
    this.joiningBonusData.activationBonusRules = rules.activationBonusRules;
    this.joiningBonusData.rideBonusRules = rules.rideBonusRules;
    this.joiningBonusData.registrationBonusRules = rules.registrationBonusRules;
    this._joiningBonusFormService.validate(this.joiningBonusData).subscribe(
      () => {
        this.stepper = ["tncs"];
        if (this.enabledStepper.indexOf("tncs") < 0) {
          this.enabledStepper.push("tncs");
        }
        this.incrementStepper.emit(75);
        this._toastMessage.openSnackBarSuccess("Successfully submitted rules");
      },
      (err) => {
        console.log("err (rules) : ", err);
        this._toastMessage.openSnackBarError(
          err && err.error && err.error.error && err.error.error.message
            ? err.error.error.message
            : "Something went wrong"
        );
      }
    );
  }

  resetRules() {
    this.activationBonus = {};
    this.rideBonusArray = [];
    this.noOfRideBonus = 0;
  }

  onSubmitTncs() {
    const tncs: any = this.termsAndConditions;
    if (!tncs || tncs.trim().length === 0) {
      return this._toastMessage.openSnackBarError(
        "Please enter a terms and condtions of the campaign."
      );
    }
    const submit = confirm(
      `Are you sure of going live with the ${this.joiningBonusData.name}`
    );
    if (submit) {
      const termsAndConditions: any = tncs
        .split(";")
        .map((term: any) => term.trim())
        .filter((term: any) => term);
      this.joiningBonusData.tncs = termsAndConditions;

      this._joiningBonusFormService.create(this.joiningBonusData).subscribe(
        () => {
          this.stepper = [];
          this.incrementStepper.emit(100);
          setTimeout(() => {
            this.router.navigate(["/joiningBonus/list"]);
          }, 1000);
        },
        (err: any) => {
          const errorMessage =
            err && err.error && err.error.error && err.error.error.message
              ? err.error.error.message
              : "Something went wrong.";
          this._toastMessage.openSnackBarError(
            errorMessage === "unable update tnc for joining bonus"
              ? "Unable to update terms and conditions."
              : errorMessage
          );
        }
      );
    }
  }

  resetTncs() {
    this.termsAndConditions = [];
  }

  expandAllAccordians() {
    this.expandAll.emit();
    this.stepper = [];
    this.stepper = this.enabledStepper;
  }

  collapseAllAccordians() {
    this.stepper = [];
    this.collapseAll.emit();
  }

  expansionPanelOpen(evt, stage) {
    if (this.stepper.indexOf(stage) < 0) {
      this.stepper = [stage];
    }
  }

  expansionPanelClose(evt, stage) {
    const tempEnabled = JSON.stringify(this.enabledStepper);
    if (this.stepper.indexOf(stage) > -1) {
      this.stepper.splice(this.stepper.indexOf(stage), 1);
    }
    this.enabledStepper = JSON.parse(tempEnabled);
  }

  isCampaignTypeAssure()
  {
    const isCampaignTypeAssure = this._joiningBonusCreatePageComponent.campaignType === 'B2B' ;
    if(isCampaignTypeAssure) 
      this.activationBonus = undefined; 
    return isCampaignTypeAssure; 
  }
}
