import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import {FormControl, Validators} from '@angular/forms';
import { ToastMessage } from '../toast-message/toast-message.service';
import { map } from 'rxjs/operators';
import { CampaignCreatePageService } from '../campaign-create-page/campaign-create-page.service';
@Component({
  selector: 'campaign-create-page',
  template: require('./campaign-create-page.component.html'),
  providers: [ToastMessage, CampaignCreatePageService]
})

export class CampaignCreatePageComponent implements OnInit {


  public data: any;
  // dropdown lists and eligible variables
  cityList = [];
  cities = {};
  modesList = [];
  modesMap = {};
  modes = {};
  cityMap = {};
  selectedCities: string[] = [];
  selectedMode: string;
  selectedModeId: string;
  serviceList: string[] = [];
  selectedServices: string[] = [];
  shiftList: string[] = [];
  selectedShifts: string[] = [];
  eligibleFor: any = '';
  servicesFetched = [];
  shiftsFetched = [];
  stepper: string[] = ['general'];
  filledForm: string[] = [];
  campaignOfferPage = false;
  generalSubmitted = false;
  campaignDescription: string;
  validatedGeneralDetails: Boolean = false;
  stepperValue = 0;
  isEdit = false;
  isViewOnly = false;
  campaignId = null;
  startDate = null;
  endDate = null;
  isCampaignActivate = false;
  code = null;
  termsAndConditions = null;

  campaignData = null;

  // By default create mode
  createMode = true;

  // Editable fields
  editCampaignDescription = true;
  editEnddate = true;
  editDescription = true;
  editCities = true;
  editEligibleRoles = true;
  editStartDate = true;
  editEndDate = true;
  editCampainRunActivity = true;
  editCampaignCode = true;
  editReferralRules = true;
  editGeneralDetails = true;
  editTncs = true;
  viewType: any;
  userSelectorList: string[] = [];
  selectedUserSelector: string[] = [];
  userSelectorsFetched = {};
  priority = 0;

  ngOnInit() {

    // if (!localStorage.getItem("userId")) {
    //   alert("Please login to access this page");
    //   this.router.navigate(['/']);
    // }

    // this._campaignCreatePageService.createReferralCampaign().subscribe(res => {
    //   this.data = res.body.result;
    // },
    // err => {
    //   this._toastMessage.openSnackBarError('Something went wrong');
    // });

    this.getCities();
    this.getModes();

    // const cookies = document.cookie.split(';');

    // const userCookie = cookies.find(function (item) {
    //   return item.includes('_id');
    // });

    // if (userCookie) {
    //   // noop
    // } else {
    //   this._toastMessage.openSnackBarError('Please login to access the campaign dashboard');
    //   this.router.navigate(['/']);
    // }

    // Get a campain id from the route url
    this.activatedRoute.params.subscribe((params: Params) => {
      let viewType ;
      if(this.activatedRoute.url['_value'].length > 1){
        viewType = this.activatedRoute.url['_value'][1].path;
      }
      if('campaignId' in params) {
        this.campaignId = params['campaignId'];
        this.viewType = viewType.charAt(0).toUpperCase() + viewType.slice(1);
        if(this.viewType === 'View'){
          this.isViewOnly = true;
          this.isEdit = false;
        }else{
          this.isViewOnly = false;
          this.isEdit = true;
        }
        this.getCampaignDetails();
      }
    });
  }

  constructor(
      private router: Router,
      private breakpointObserver: BreakpointObserver,
      private _toastMessage: ToastMessage,
      private _campaignCreatePageService: CampaignCreatePageService,
      private activatedRoute: ActivatedRoute
  ) { }

  logout() {
    document.cookie = ' ';
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    this._toastMessage.openSnackBarSuccess('You have been logged out');
    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    this.router.navigate(['/']);
  }

  backToList() {
    this.router.navigate(['/campaign/list']);
  }

  getCities() {
    this._campaignCreatePageService.getCityList().subscribe(res => {
      const cityList = [];
      this.cities = res.data.cities;
      for (const city in this.cities) {
        if (this.cities[city]) {
          const name = this.cities[city]['displayName'];
          this.cityMap[name] = this.cities[city]['_id'];
          cityList.push(name);
        }
      }
      this.cityList = cityList;
    },
    err => {
      this._toastMessage.openSnackBarError('Unable to fetch cities.');
    });
  }

  typeChanged () {
    this.userSelectorList = [];
    this.selectedUserSelector = [];
    this.userSelectorsFetched = [];
    if (this.selectedCities.length == 1)
        this.fetchUserSelectors();
  }

  getModes() {
    this._campaignCreatePageService.getModes().subscribe(res => {
      const modesList = [];
      this.modes = res.data;
      for (const mode in this.modes) {
        if (this.modes[mode]) {
          const modeName = this.modes[mode]['mode'];
          this.modesMap[modeName] = this.modes[mode]['_id'];
          modesList.push(modeName);
        }
      }
      this.modesList = modesList;
    },
    err => {
      this._toastMessage.openSnackBarError('Unable to fetch modes.');
    });
  }

  // Formate date fron tiestanp to DD/MM/YYYY format
  formatDate = (timestamp: any) => {
    const dateObj = new Date(timestamp);
    return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
  }

  setEditableFields = (isCampaignActivate: Boolean) => {
    if(isCampaignActivate) {
      this.editCities = false;
      this.editEligibleRoles = false;
      this.editStartDate = false;
      this.editCampainRunActivity = false;
      this.editCampaignDescription = false;
      this.editReferralRules = false;
      this.editCampaignCode = false;
      this.editGeneralDetails = false;
      this.editTncs = false;
      this.campaignOfferPage = true; // To Expand all sections
    }
  }
  
  setCampaignData = (campaign: any) => { 
    this.campaignData = {
      'campaignId' : campaign._id,
      'campaignCode' : campaign.code,
      'referralRules' : campaign.referralRules
    }
  }

  getCampaignTncs = (tncs: any) => {
    // Format tncs by repacing commma with collon
    return tncs.join(';');
  }
  
  getCampaignDetails = () => {
    this._campaignCreatePageService.getCampaignDetails(this.campaignId).subscribe((data: any) => {
      const campaign = data.result;
      this.setCampaignData(campaign);
      this.campaignDescription = campaign.description;
      this.selectedCities = campaign.cities;
      this.eligibleFor = campaign.role;
      this.startDate = this.formatDate(campaign.startDate);
      this.endDate = this.formatDate(campaign.endDate);
      this.isCampaignActivate = campaign.isActive;
      this.code = campaign.code;
      this.termsAndConditions = this.getCampaignTncs(campaign.tncs);
      this.selectedMode = campaign.mode && campaign.mode.mode || "";
      this.priority = campaign.priority || 0;
      this.selectedUserSelector = campaign.userSelector || []
      
      // Sets the editable fields
      this.setEditableFields(campaign.isActive);
    },
    (error: any) => {
      console.log("****************ERROR",error );
    })
  }

  cityChanged() {
    this.userSelectorList = [];
    this.selectedUserSelector = [];
    this.userSelectorsFetched = [];
    if (this.selectedCities.length == 1)
        this.fetchUserSelectors();
  }

  fetchUserSelectors() {
    this.userSelectorList = [];
    if (this.selectedCities.length == 1) {
      const cityId = this.cityMap[this.selectedCities[0]];
      let userType = this.eligibleFor == "CUSTOMER" ? "customer" : "rider";
      this._campaignCreatePageService.getUserSelectorList(cityId, userType).subscribe(res => {
        const finalUserSelectors = [];
        res.data.selectors.forEach((userSelector) => {
          const userSelectorName = userSelector && userSelector.name;
          this.userSelectorsFetched[userSelectorName] = userSelector._id;
          finalUserSelectors.push(userSelectorName);
        })
        this.userSelectorList = finalUserSelectors;
      }, (err) => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this._toastMessage.openSnackBarError(err.error.error.message);
        } else {
          this._toastMessage.openSnackBarError('Unable to fetch user selectors');
        }
      });
    }
  }

  fetchServices() {
    this.serviceList = [];
    this.servicesFetched = [];
    for (const city in this.selectedCities) {
      if (city) {
        const cityId = this.cityMap[this.selectedCities[city]];
        this._campaignCreatePageService.getServicesList(cityId).subscribe(res => {
          this.servicesFetched.push(...res.data);
          this.getServiceIntersection();
        }, error => {
          this._toastMessage.openSnackBarError('Unable to fetch services.');
        });
      }
    }
  }

  getServiceIntersection() {
    const serviceCounts = {};
    for (const service in this.servicesFetched) {
      if (service) {
        const serviceName = this.servicesFetched[service] && this.servicesFetched[service]['service'] 
                              && this.servicesFetched[service]['service']['name'];
        if (serviceCounts[serviceName]) {
          serviceCounts[serviceName] = serviceCounts[serviceName] + 1;
        } else {
          serviceCounts[serviceName] = 1;
        }
      }
    }
    const vm = this;
    const finalServices = [];
    Object.keys(serviceCounts).forEach(function(key) {
      if (serviceCounts[key] === vm.selectedCities.length) {
        finalServices.push(key);
      }
    });
    this.serviceList = finalServices;
  }

  fetchShifts() {
    this.shiftList = [];
    this.shiftsFetched = [];
    for (const city in this.selectedCities) {
      if (city) {
        this._campaignCreatePageService.getShiftsList(this.selectedCities[city]).subscribe(res => {
          this.shiftsFetched.push(...res.data);
          this.getShiftIntersection();
        }, error => {
          this._toastMessage.openSnackBarError('Unable to fetch shifts.');
        });
      }
    }
  }

  getShiftIntersection() {
    const shiftCounts = {};
    for (const shift in this.shiftsFetched) {
      if (this.shiftsFetched[shift]) {
        if (shiftCounts[this.shiftsFetched[shift]]) {
          shiftCounts[this.shiftsFetched[shift]] = shiftCounts[this.shiftsFetched[shift]] + 1;
        } else {
          shiftCounts[this.shiftsFetched[shift]] = 1;
        }
      }
    }
    const vm = this;
    const finalShifts = [];
    Object.keys(shiftCounts).forEach(function(key) {
      if (shiftCounts[key] === vm.selectedCities.length) {
        finalShifts.push(key);
      }
    });
    this.shiftList = finalShifts;
  }

  stepperIncrement(num) {
    this.stepperValue = num;
    if (this.stepperValue === 100) {
      this._toastMessage.openSnackBarSuccess('You\'re all done!');
    }
  }
  handleCampaignTypeChange(evt: any) {
  }
  handleCampaignSubTypeChange(evt: any) {
    this.stepperIncrement(10);
    this.stepper = ['general'];
    if (this.filledForm.indexOf('type') < 0) {
      this.filledForm.push('type');
    }
  }

  resetGeneralDetails() {
    this.campaignDescription = null;
    this.selectedCities = [];
    this.selectedServices = null;
    this.selectedShifts = null;
    this.eligibleFor = null;
    this.selectedMode = null;
    this.selectedUserSelector = [];
    this.userSelectorList = [];
    this.priority = 0;
  }

  onSubmitGenaralDetails = () => {
    const selectedModeId = this.modesMap[this.selectedMode];
    let selectedUserSelectorList = [];
    for (let i = 0; i < this.selectedUserSelector.length; i++) {
      selectedUserSelectorList.push(this.userSelectorsFetched[this.selectedUserSelector[i]])
    }
    this._campaignCreatePageService.submitReferralGeneralDetails(this.campaignDescription, this.eligibleFor,
      this.selectedCities, this.selectedShifts, this.selectedServices, this.selectedMode, selectedModeId, selectedUserSelectorList, this.priority).subscribe(res => {
      this.data = res.body.result;
      this._toastMessage.openSnackBarSuccess('General details submitted');
      this.stepper = [];
      if (this.filledForm.indexOf('general') < 0) {
        this.filledForm.push('general');
      }
      this.generalSubmitted = true;
      this.onNext();
    },
    err => {
      this._toastMessage.openSnackBarError('Something went wrong');
    });
    this.validatedGeneralDetails = false;
  }

  submitGeneralCampaignDetails() {
    // No client side validation on edit camapign
    if(this.isEdit) {
      this.onSubmitGenaralDetails();
      return;
    }
    if (this.selectedCities.length > 1) {
      this.selectedUserSelector = [];
      this.userSelectorList = [];
      this.priority = 0;
    }
    if (!this.campaignDescription || this.campaignDescription.trim().length === 0) {
      this._toastMessage.openSnackBarError('Please enter a description about this campaign');
    } else if (!this.eligibleFor) {
      this._toastMessage.openSnackBarError('Select who benefits from this campaign');
    } else {
      this.campaignDescription = this.campaignDescription.trim();
      if (this.selectedCities.length === 0) {
        this._toastMessage.openSnackBarError('Please select the cities that this campaign will run in');
      } else {
        this.validatedGeneralDetails = true;
      }
    }

    if (this.priority < 0) {
      this._toastMessage.openSnackBarError('Priority cannot be negative');
      this.validatedGeneralDetails = false;
    }
    if (!this.selectedUserSelector.length && this.priority) {
      this._toastMessage.openSnackBarError('Please select a user selector for entering priority');
      this.validatedGeneralDetails = false;
    }
    if (this.selectedUserSelector.length && !this.priority) {
      this._toastMessage.openSnackBarError('Please enter a priority greater than 0');
      this.validatedGeneralDetails = false;
    }
    if (this.eligibleFor == "RIDER" && !this.selectedMode) {
      this._toastMessage.openSnackBarError('Please select a valid mode for captain');
      this.validatedGeneralDetails = false;
    }
    if (this.validatedGeneralDetails === true) {
      this.onSubmitGenaralDetails();
    }
  }

  expansionPanelOpen(evt, stage) {
    if (this.stepper.indexOf(stage) < 0) {
      this.stepper.push(stage);
    }
  }

  expansionPanelClose(evt, stage) {
    if (this.stepper.indexOf(stage) > -1) {
     this.stepper.splice(this.stepper.indexOf(stage), 1);
    }
  }

  expandAll() {
    this.stepper = ['type', 'general'];
  }

  collapseAll() {
    this.stepper = [];
  }

  getGenaralDetailsEditStatus() {
    if (this.isEdit) {
      return !this.editGeneralDetails;
    }
    return this.generalSubmitted;
  }

  isDisabled() {
    let disabled = false
    if (this.viewType === "Edit") {
      disabled = this.getGenaralDetailsEditStatus() || this.isEdit
    }else if(this.viewType === "View" ){
      disabled = true;
    }
    return disabled;
  }

  onNext(){
    this.stepperIncrement(20);
    if (!this.campaignOfferPage) {
      this._toastMessage.openSnackBarSuccess('You can now click Expand All and Collapse All on the bottom right for this panel');
    }
    this.campaignOfferPage = true;
  }
}
