import { Injectable } from '@angular/core';

@Injectable()
export class LoaderService {
    loading = false;
    constructor() { }
    private openCount = 0;
    
    openLoading(message = '') {
        this.openCount++;
        this.loading = true;
    }

    closeLoading() {
        if (this.openCount > 0) {
            this.openCount--;
        }
        if (this.openCount === 0) {
            this.loading = false;
        }
    }

    forceClose() {
        this.openCount = 0;
        this.loading = false;
    }
}
