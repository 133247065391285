import { Component, SimpleChanges, OnInit, Input, OnChanges } from '@angular/core';
import { ToastMessage } from '../toast-message/toast-message.service';
import { DataSource } from '@angular/cdk/table';

@Component({
  selector: 'performance-table',
  template: require('./performance-table.component.html')
})
export class PerformanceTableComponent implements OnInit, OnChanges {
  @Input() dataSource: any;
  public showRegSubRules = false;
  public showActSubRules = false;
  public showOrderSubRules = false;

  constructor(private _toastMessage: ToastMessage) { }

  ngOnInit() {
    for (const rule in this.dataSource) {
      if (rule && this.dataSource[rule]) {
        var subrule = this.dataSource[rule].tableData[0].sub_rules;
        if (subrule && subrule.length === 1) {
          for (const values in subrule) {
            if (values) {
              var empty = true;
              if (values) {
                for (const data in subrule[values]) {
                  if (subrule[values][data] ) {
                    empty = false;
                    break;
                  }
                }
              }
            }
          }
          if (empty) {
            this.dataSource[rule].tableData[0].sub_rules = [];
          }
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.dataSource && changes.dataSource.currentValue) {
    }
  }

  toggleRows(id) {
    if (this.dataSource[id] && this.dataSource[id].data && this.dataSource[id].data.length === 0) {
      this._toastMessage.openSnackBarError('No sub rules found for this rule');
    }
  }
}
