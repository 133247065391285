import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CampaignCreatePageComponent} from './campaign-create-page/campaign-create-page.component';
import {CampaignListPageComponent} from './campaign-list-page/campaign-list-page.component';
import {CampaignPerformanceComponent} from './campaign-performance/campaign-performance.component';

import {CampaignReferralSearchComponent} from './campaign-referral-search/campaign-referral-search.component';

import { SelectivePreloadingStrategy } from './selective-preloading-strategy';
import {SigninComponent} from './signin/signin.component';
import { JoiningBonusListPageComponent } from './joining-bonus-list-page/joining-bonus-list-page.component';
import { JoiningBonusCreatePageComponent } from './joining-bonus-create-page/joining-bonus-create-page.component';
import { RejoiningBonusListPageComponent } from './rejoining-bonus-list-page/rejoining-bonus-list-page.component';
import { RejoiningBonusCreatePageComponent } from './rejoining-bonus-create-page/rejoining-bonus-create-page.component';

const appRoutes: Routes = [
  {
    path: '',
    component: SigninComponent
  },
  {
    path: 'campaign',
    component: CampaignCreatePageComponent
  },
  {
    path: 'campaign/list',
    component: CampaignListPageComponent
  },
  {
    path: 'performance',
    component: CampaignPerformanceComponent
  },
  {
    path: 'search',
    component: CampaignReferralSearchComponent
  },
  {
    path: 'campaign/edit/:campaignId',
    component: CampaignCreatePageComponent
  },
  {
    path: 'campaign/view/:campaignId',
    component: CampaignCreatePageComponent
  },
  {
    path: 'joiningBonus',
    component: JoiningBonusCreatePageComponent
  },
  {
    path: 'joiningBonus/list',
    component: JoiningBonusListPageComponent
  },
  {
    path: 'rejoiningBonus',
    component: RejoiningBonusCreatePageComponent
  },
  {
    path: 'rejoiningBonus/list',
    component: RejoiningBonusListPageComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false, // <-- debugging purposes only
        preloadingStrategy: SelectivePreloadingStrategy,

      }
    )
  ],
  exports: [
    RouterModule
  ],
  providers: [
    SelectivePreloadingStrategy
  ]
})
export class AppRoutingModule { }
