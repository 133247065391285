import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getCookie } from './util';
import { environment } from '../environments/environment'

@Injectable()
export class AccessRightsService {
  userRoles: any;
  getAccessRightsUrl = environment.SERVER + '/api/getUserAccessRights';

  constructor(private http: HttpClient) { }

  getUserAccessRights() {
    return this.http.post<any>(
      this.getAccessRightsUrl, 
      { roles: getCookie('userRoles').split(',') }
    );
  }
}
