import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'

const httpOptions = {
    headers: new HttpHeaders({
      'x-consumer-username' : '5c9b45b6b50ab0456be5af2c'
    })
};
@Injectable()
export class CampaignFormService {
//   configUrl = '../src/app/onboarding-page/basic-info/riderDetails.json';
  referralWhenDetails = environment.SERVER + '/api/submitReferralWhenDetails';
  referralMessageDetails = environment.SERVER + '/api/submitReferralMessageDetails';
  referralHowDetails = environment.SERVER + '/api/submitReferralHowDetails';
  checkCodeAvailability = environment.SERVER + '/api/checkReferralCodeAvailability';
  referralCodeDetails = environment.SERVER + '/api/submitReferralCodeDetails';
  termsAndConditions = environment.SERVER + '/api/submitTermsAndConditions';
  getServicesURL = environment.SERVER + '/api/get/services';

  constructor(private http: HttpClient) { }

  submitReferralWhenDetails(start, end, id) {
    const payload = {
      'body' : {
        'startDate' : start,
        'endDate' : end
      },
      'id' : id,
      'userId' : localStorage.getItem('userId')
    };
    return this.http.put<any>(this.referralWhenDetails, payload, httpOptions);
  }

  submitReferralHowDetails(campaignRules, id) {
    const payload = {
      'body' : {
        'campaignRules' : campaignRules
      },
      'id' : id,
      'userId' : localStorage.getItem('userId')
    };
    return this.http.put<any>(this.referralHowDetails, payload, httpOptions);
  }

  submitReferralMessageDetails(shortDisplayMessage, longDisplayMessage, successMessageToReferred,
                                failureMessageToReferred, successMessageToReferrer, id) {
    const payload = {
      'body' : {
        'shortDisplayMessage' : shortDisplayMessage,
        'longDisplayMessage' : longDisplayMessage,
        'successMessageToReferred' : successMessageToReferred,
        'failureMessageToReferred' : failureMessageToReferred,
        'successMessageToReferrer' : successMessageToReferrer
      },
      'id' : id,
      'userId' : localStorage.getItem('userId')
    };
    return this.http.put<any>(this.referralMessageDetails, payload, httpOptions);
  }

  checkReferralCodeAvailability(code, id) {
    var body = {
      'campaignCode': code
    };
    return this.http.post<any>(this.checkCodeAvailability + '/' + id, body);
  }

  submitReferralCodeDetails(code, activate, id) {
    const payload = {
      'body' : {
        'campaignCode' : code,
        'isActive' : activate
      },
      'id' : id,
      'userId' : localStorage.getItem('userId')
    };
    return this.http.put<any>(this.referralCodeDetails, payload, httpOptions);
  }

  submitTermsAndConditions(
    termsAndConditions: any,
    campaignId: any
  ) {
    const payload = {
      tncs: termsAndConditions,
      campaignId,
      'key': 'KJHKJ87UGJ8HQIO9XVC',
      'userId': localStorage.getItem('userId')
    };

    return this.http.put<any>(this.termsAndConditions, payload, httpOptions);
  }

  getServicesList(cities: String[]) {
    const payload = { 'city': cities };
    return this.http.post<any>(this.getServicesURL, payload);
  }
}
