import { Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  template: require('./loader.component.html')
})
export class LoaderComponent {
  loading = true;
  constructor(
    public loaderService: LoaderService,
  ) { }

}
