import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-referral-search',
  template: require('./referral-search.component.html')
})
export class ReferralSearchComponent {
  phonenumber: any;
  role: string;
  isError = false;
  errorMessage: string;
  roles: string[] = ['Captain', 'Customer'];
  phonenumberPattern = /^[0-9]+$/;
  this: any;

  constructor(private router: Router) {}

  setError = (errorMessage: string): void => {
    this.isError = true;
    this.errorMessage = errorMessage;
  }

  resetError = (): void => {
    this.isError = false;
    this.errorMessage = '';
  }

  onPhoneNumberChange = (inputDigit: any): any => {
    if (!this.phonenumberPattern.test(inputDigit)) {
      return this.setError('Phone number should contain only numbers.');
    }
    this.resetError();
  }

  onSearchSubmit = (): any => {
    if (!this.role || !this.role.length) {
      return this.setError('Please select a role.');
    }

    if (this.phonenumber && this.phonenumber.length < 10) {
      return this.setError('Please enter 10  digit mobile number');
    }

    this.resetError();

    this.router.navigate(['/search'], {
      queryParams: {
        mobileNumber: this.phonenumber,
        role: this.role === 'Captain' ? 'Rider' : this.role
      }
    });
  }

  getSearchButtonStatus = (): boolean => {
    return !(this.role && this.phonenumber);
  }
}
