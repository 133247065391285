import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';



@Component({
  selector: 'app-root',
  template: require('./app.component.html')
})
export class AppComponent {
  title = 'Coupons Dashboard';
  public navFirstItem: string;
  public navSecondItem: string;
  public showSideNav: boolean = false;


  constructor() {

  }

}
