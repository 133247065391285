import { Component, OnInit } from '@angular/core';
import {SigninService} from './signin.service';
import {Router} from "@angular/router";
import { AccessRightsService } from '../access-rights.service';
import { setCookie } from '../util';
import { environment } from '../../environments/environment';

import { ToastMessage } from '../toast-message/toast-message.service';

declare let google: any;

@Component({
  selector: 'signin',
  template: require('./signin.component.html'),
  providers: [SigninService,ToastMessage]
})
export class SigninComponent implements OnInit {
  config:any;
  loginInfo:any;
  private auth2: any;
  public user:any = {};
  public profile: any = {};
  public authResponseObj : any = {};

  constructor(
    private _signinService: SigninService,
    private _accessRightsService: AccessRightsService,
    private router: Router,
    private _toastMessage: ToastMessage) { 
      let signInJS = document.createElement('script');
      signInJS.async = true;
      signInJS.src = 'https://accounts.google.com/gsi/client';
      // signInJS.src = 'https://rapido-generic-assets.s3.ap-south-1.amazonaws.com/gsi-client.js';
      signInJS.onload = () => {
        this.auth2 = google.accounts.oauth2.initTokenClient({
          client_id: environment.googleAccountKey,
          scope: 'email',
          callback: (tokenResponse) => {
            const accessToken = tokenResponse.access_token;
            this.signInCallback(accessToken);
          },
        });
      };
      document.head.appendChild(signInJS);
  }
  ngOnInit() {
    if(document.getElementById('BlowupLens')){
      if(window){
        window['jQuery']('#BlowupLens').hide();
      }
    }
  }
  showUserData(data) {
    this._signinService.getLoginResponse(data).subscribe(resp => {
      setCookie('token', resp.body.info['token'], 7);

      this.config = { ...resp.body };
      this.loginInfo = this.config.data;
        if(this.config.info && this.config.info.status){
       
          this._toastMessage.openSnackBarSuccess("logged in successfully");
          if(this.config.info.status=='success'){
            let response;
            this._signinService.getUserInfo(data.email).subscribe(res=>{
              response = { ...res };
              if(response[0] && response[0]._id){
                localStorage.setItem("userId",response[0]._id);
                document.cookie = '{"_id":"' +response[0]._id+'"}';
                this._signinService.getUserRoles(response[0]._id).subscribe(res=>{
                  localStorage.setItem("permissions",res.data);
                });
                this.router.navigate(['/campaign/list']);

                setCookie('userRoles', response[0].roles, 7);
              }
              else{
                this._toastMessage.openSnackBarError("User not found! Please contact support.")
              }

            },
            err=>{
              const errorMessage = err.error && err.error.message || err;
              this._toastMessage.openSnackBarError(errorMessage);
            });
          }
        }
    }, error => {
      const errorMessage = error && error.error && error.error.message || 'Could not login';
      this._toastMessage.openSnackBarError(errorMessage);
    });
  }

  // showUserData(data) {
  //   this._signinService.getLoginResponse(data).subscribe(resp => {

  //     this.config = { ...resp.body };
  //     this.loginInfo = this.config.data;
  //       // if(this.config.info && this.config.info.token){
  //       //   localStorage.setItem("token",this.config.info.token)
  //       // }
  //       if(this.config.info && this.config.info.status){
       
  //         this._toastMessage.openSnackBarSuccess("logged in successfully");
  //         if(this.config.info.status=='success'){
  //           let response;
  //           this._signinService.getUserInfo(data.email).subscribe(res=>{
  //             response = { ...res };
              
  //              this.router.navigate(['/campaign']);
  //           });
  //         }
  //       }
  //   })
  // }

  signInCallback(token) {
    this._signinService.getUserAccessToken(token).subscribe((result: any)=> {
        const profile = result.info.result;
        this.user.id = profile.sub;
        this.user.name = profile.name;
        this.user.accessToken = token;
        this.user.image = profile.picture;
        this.user.email = profile.email;
        this.user.hd = profile.hd;
        this.showUserData(this.user);
    });
  }

  public socialSignIn() {
    this.auth2.requestAccessToken();
  }
}
