import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'

@Injectable()
export class RejoiningBonusCreatePageService {
  validateRejoiningBonus = environment.SERVER + '/api/rejoiningBonus/validate';
  getUserSelectorsURL = environment.SERVER + '/api/userSelectors';
  getCitiesUrl = environment.SERVER + '/api/get/cities';
  getServicesURL = environment.SERVER + '/api/get/services';

  constructor(private http: HttpClient) { }

  getCityList() {
    return this.http.get<any>(this.getCitiesUrl);
  }

  getUserSelectorList(city, servicedetails=[]) {
    const queries = [];
    queries.push(`city=${city}`)
    queries.push('userType=rider')
    queries.push('active=true')
    queries.push('type=static,custom')
    if (Array.isArray(servicedetails) && servicedetails.length) {
      queries.push(`service=${servicedetails.join(',')}`)
    }
    const queryParams = queries.length > 0 ? '?' + queries.join('&') : '';
    return this.http.get<any>(this.getUserSelectorsURL + queryParams);
  }

  getServicesList(city) {
    const payload = { 'city': city };
    return this.http.post<any>(this.getServicesURL, payload);
  }

  validate(data) {
    const payload = {
      'body': data,
    };
    return this.http.post<any>(this.validateRejoiningBonus, payload);
  }
}