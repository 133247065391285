import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';


import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'


const httpOptions = {
  headers: new HttpHeaders({
    'x-consumer-username' : '5c9b45b6b50ab0456be5af2c'
  })
};
@Injectable()
export class CampaignCreatePageService {
  // configUrl = '../src/app/onboarding-page/basic-info/riderDetails.json';
  campaignGeneralDetails =environment.SERVER + '/api/submitReferralGeneralDetails';
  createReferral =environment.SERVER + '/api/createReferralCampaign';
  getShiftsURL =environment.SERVER + '/api/get/shifts';
  getServicesURL =environment.SERVER + '/api/get/services';
  getCitiesUrl =environment.SERVER + '/api/get/cities';
  getModesUrl =environment.SERVER + '/api/mode';
  getCampaignDetailsById =environment.SERVER + '/api/get/campaignDetails';
  getUserSelectorsURL =environment.SERVER + '/api/userSelectors';

  constructor(private http: HttpClient) { }

  createReferralCampaign() {
    const payload = {
      'userId' : localStorage.getItem('userId')
    }
    return this.http.post<any>(this.createReferral, payload, httpOptions);
  }

  getUserSelectorList(city, userType) {
    const queries = [];
    queries.push(`city=${city}`)
    queries.push(`userType=${userType}`)
    queries.push('active=true')
    queries.push('type=static,custom')
    const queryParams = queries.length > 0 ? '?' + queries.join('&') : '';
    return this.http.get<any>(this.getUserSelectorsURL + queryParams);
  }

  getCityList() {
    return this.http.get<any>(this.getCitiesUrl);
  }

  getModes() {
    return this.http.get<any>(this.getModesUrl);
  }

  getShiftsList (city) {
    return this.http.get<any>(this.getShiftsURL + '/' + city);
  }

  getServicesList(city) {
    const payload = {'city': city};
    return this.http.post<any>(this.getServicesURL, payload, httpOptions);
  }

  submitReferralGeneralDetails(
    description: any, 
    role: any, 
    cities: any, 
    shifts: any,
    services: any,
    mode: any,
    modeId: any,
    selectedUserSelectorList: any,
    priority: any) {
    const payload = {
      'body' : {
        'description': description,
        'role': role,
        'cities': cities,
        'userSelector': [],
        'priority': 0
      },
      'userId' : localStorage.getItem('userId')
    };
    if (role === 'rider') {
      payload['body']['shifts'] = shifts;
      payload['body']['services'] = services;
    }
    if (role == "RIDER") {
      payload['body']['mode'] = {
        mode,
        modeId
      }
    }
    payload['body']['userSelector'] = selectedUserSelectorList;
    payload['body']['priority'] = priority;

    return this.http.put<any>(this.campaignGeneralDetails, payload, httpOptions);
  }


  getCampaignDetails(campaignId: any) {
    const userId = localStorage.getItem('userId');
    return this.http.get<any>(`${this.getCampaignDetailsById}/${campaignId}?userId=${userId}`,httpOptions);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
