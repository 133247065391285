import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { RegistrationBonusConfig } from "./joining-bonus-form.model";

@Injectable()
export class JoiningBonusFormService {
  validateAPI = environment.SERVER + "/api/joiningBonus/validate";
  createAPI = environment.SERVER + "/api/joiningBonus";
  bonusConfigAPI = environment.SERVER + "/api/getRegistrationBonusConfig";
  constructor(private http: HttpClient) {}

  validate(data) {
    const payload = {
      body: data,
    };
    return this.http.post<any>(this.validateAPI, payload);
  }

  create(data) {
    const payload = {
      body: data,
    };
    return this.http.post<any>(this.createAPI, payload);
  }

  config() {
    return this.http.get<RegistrationBonusConfig>(this.bonusConfigAPI);
  }
}
