import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment'


const httpOptions = {
  headers: new HttpHeaders({
    'appid' : '2',
    'Content-type' : 'application/json',
    'userId' : localStorage.getItem('userId'),
    'key' : 'KJHKJ87UGJ8HQIO9XVC',
    'x-consumer-username' : '5c9b45b6b50ab0456be5af2c'
  })
};
@Injectable()
export class CampaignListPageService {
  getListDataUrl = environment.SERVER + '/api/getListData';
  getCampaignInfoUrl = environment.SERVER + '/api/getCampaignInfo';
  activateCampaign = environment.SERVER + '/api/activateCampaign';
  deactivateCampaign = environment.SERVER + '/api/deactivateCampaign';


  constructor(private http: HttpClient) { }

  getListData(limit, offset, code, city, role, status) {
    const queries = [];
    let string = '';
    if (limit) {
        string = 'limit=' + limit;
        queries.push(string);
    }
    string = 'offset=' + offset || '0';
    queries.push(string);
    if (code) {
        string = 'campaign_code__regex=' + code;
        queries.push(string);
    }
    if (city) {
        string = 'city__regex=' + city;
        queries.push(string);
    }
    if (role) {
        string = 'role__regex=' + role;
        queries.push(string);
    }
    if (status && status != "All") {
      string = 'status=' + status.toLowerCase();
      queries.push(string);
    }
    const queryParams = queries.length > 0 ? '?' + queries.join('&') : '';
    return this.http.get<any>(this.getListDataUrl + queryParams);
  }

  getCampaignInfo(campaignId) {
    return this.http.get<any>(this.getCampaignInfoUrl + '/' + campaignId);
  }

  activate(id) {
    var payload = {
        'body' : {
            'id' : id
        },
        'userId' : localStorage.getItem('userId')
    }
    return this.http.put<any>(this.activateCampaign, payload);
  }

  deactivate(id) {
    var payload = {
        'body' : {
            'id' : id
        },
        'userId' : localStorage.getItem('userId')
    }
    return this.http.put<any>(this.deactivateCampaign, payload);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };



}
