import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CampaignReferralSearchService } from './campaign-referral-search.service';
import { LoaderService } from '../loader/loader.service';

@Component({
  selector: 'app-campaign-referral-search',
  template: require('./campaign-referral-search.component.html'),
  providers: [CampaignReferralSearchService]
})

export class CampaignReferralSearchComponent implements OnInit {
  isMoneySpentMetricCard = true;
  startDate: any;
  endDate: any;
  campaigns: any;
  phoneNumber: any;
  role: any;
  metrics: any;
  metricData: any;
  noCampaignId = [];
  monthMap = {
    1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun',
    7: 'Jul', 8: 'Aug', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dec'
  };
  metricsData: {};
  updatedMetricsData: any[];
  errorMessage: any;

  constructor(
    private campaignReferralSearchService: CampaignReferralSearchService,
    private loaderService: LoaderService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  get3MonthsPriorDate = (): any => {
    const threeMonthsPriorDate: Date = new Date();
    threeMonthsPriorDate.setDate(threeMonthsPriorDate.getDate() - 89);
    return threeMonthsPriorDate;
  }

  setQueryParam = (): void => {
    const params = this.route.snapshot && this.route.snapshot.queryParamMap;
    this.phoneNumber = params.get('mobileNumber');
    this.role = params.get('role');
  }

  ngOnInit() {
    this.setQueryParam();
    this.endDate = new Date();
    this.startDate = this.get3MonthsPriorDate();
    this.loaderService.openLoading();
    this.getStats();
  }

  navigateToCampaignList = (): void => {
    this.router.navigate(['/campaign/list']);
  }

  getDateFormatted(date: any) {
    if (date) {
      const today = new Date(parseFloat(date));
      let dd: any = today.getDate();
      const mm: any = today.getMonth() + 1;
      const yyyy: any = today.getFullYear().toString().substr(-2);
      if (dd < 10) {
        dd = '0' + dd;
      }
      const dateInfo = dd + ' ' + this.monthMap[mm] + ' \'' + yyyy;
      return dateInfo;
    } else {
      return 'N/A';
    }
  }

  changeInStartDate = () => {
    this.startDate = this.startDate;
    this.loaderService.openLoading();
    this.getStats();
  }

  changeInEndDate = () => {
    this.endDate = this.endDate;
    this.loaderService.openLoading();
    this.getStats();
  }

  getUserMappedRules = (campaignId: any, user: any) => {
    if (!campaignId) {
      this.noCampaignId.push(user);
      return;
    }
    this.updatedMetricsData.map((campaign: any, index: any) => {
      if (campaign.campaignId === campaignId) {
        if (user.userMessage === 'Registered with Rapido') {
          campaign.registrationUsers.push(user);
        }
        else {
          campaign.activationUsers.push(user);
        } 
        if(user.count > 0) {
          campaign.orderUsers.push(user);
        }
      }
    });
  }

  getQueryParams = () => {
    return ({
      mobile: this.phoneNumber,
      role: this.role.toUpperCase(),
      startDate: this.startDate.getTime(),
      endDate: this.endDate.getTime()
    });
  }

  mapUsersToRules = (): any => {
    this.metrics.referredUsers.map((eachUser: any) => {
      const user = {
        'userMessage': eachUser.message,
        'username': eachUser.name,
        'mobile': eachUser.mobile,
        'count' : eachUser.count,
        'matchedIncentive': eachUser.matchedIncentive,
        'daysSinceActivation': eachUser.daysSinceActivation
      };
      const campaignId = eachUser.campaignData ? eachUser.campaignData['_id'] : '';
      this.getUserMappedRules(campaignId, user);
    });
  }

  modifyCampaignData = (metrics: any): any => {
    Object.keys(metrics).map((campaignId: any) => {
      const referredUser = metrics[campaignId];
      const data = {
        ...referredUser[0],
        campaignId,
        'rules': referredUser[0].campaignData.rules,
        'activationUsers': [],
        'registrationUsers': [],
        'orderUsers': []
      };
      this.updatedMetricsData.push(data);
    });
  }

  formatCampaignDataForUI = (): any => {
    this.updatedMetricsData.map((eachData: any) => {
      const data = Object.assign(
        {},
        {
          campaignId: eachData.campaignData._id,
          startDate: this.getDateFormatted(eachData.campaignData.startDate),
          endDate: this.getDateFormatted(eachData.campaignData.endDate),
          rules: eachData.campaignData.rules,
          orderUsers: eachData.orderUsers,
          registrationUsers: eachData.registrationUsers,
          activationUsers: eachData.activationUsers
        }
      );
      this.metricData.push(data);
    });
  }

  formatResponse = (): any => {
    return this.metrics.referredUsers.reduce((results: any, org: any) => {
      if (org.campaignData) {
        (results[org.campaignData._id] = results[org.campaignData._id] || []).push(org);
      }
      return results;
    }, {});
  }

  getStats = (): any => {
    const queryParams = this.getQueryParams();
    this.campaignReferralSearchService.getUserActivity(queryParams).subscribe(
      (response: any) => {
        this.loaderService.closeLoading();
        this.metrics = response.result;
        this.errorMessage = '';
        this.metricData = [];
        this.updatedMetricsData = [];

        const metrics = this.formatResponse();
        this.modifyCampaignData(metrics);
        this.mapUsersToRules();
        this.formatCampaignDataForUI();
      },
     (errorResponse: any) => {
        this.loaderService.closeLoading();
        this.errorMessage = (errorResponse.error && errorResponse.error.data && errorResponse.error.data.message) ?
          errorResponse.error.data.message : 'Unable to get user activity.';
     }
    );
  }

  getReferrerName = (): any => {
    if (this.metrics.isUsageNotExisting) {
      return 'ORGANIC USER';
    }
    return (this.metrics.referrerUserDetails && this.metrics.referrerUserDetails.name ?
        'Referred By ' + this.metrics.referrerUserDetails.name : '');
  }

  getReferrerMobileNumber = (): any =>{
    if (this.metrics.isUsageNotExisting) {
      return '';
    }
    return (this.metrics.referrerUserDetails && this.metrics.referrerUserDetails.mobile ?
      this.metrics.referrerUserDetails.mobile : '')
  }

  getHeaderCaption = (): any => {
    if (!this.metrics) {
      return;
    }
    if (this.getReferrerMobileNumber() === ''){
      return `${this.phoneNumber} - ${this.metrics.userName} - ${this.getReferrerName()}`;
    }
    return `${this.phoneNumber} - ${this.metrics.userName} - ${this.getReferrerName()} - ${this.getReferrerMobileNumber()}`;
  }

  getNumberOfUsersReferred = (): number => {
    return (this.metrics && this.metrics.referredUsers) ? this.metrics.referredUsers.length : 0;
  }

  getAmountReceived = (): any => {
    return (this.metrics && !this.isEmpty(this.metrics.totalMoney) &&
      !this.isEmpty(this.metrics.incentiveEarned)) ? (this.metrics.totalMoney - this.metrics.incentiveEarned) : 0;
  }

  getTotalEarning = (): any => {
    return (this.metrics && !this.isEmpty(this.metrics.totalMoney)) ? this.metrics.totalMoney : 0;
  }
  
  isEmpty(value) {
    return (value === null || value.length === 0);
  }

  getAmountSpentOnNewUser = (): any => {
    if (this.metricData && this.metricData.length === 0) { return 0; }
    const amountSpentOnNewUser = this.metricData && this.metricData.map((metric: any) => {
      if (metric && metric.rules) {
        return metric.rules.map((rule: any) => {
          let userLength: any;
          if (rule.ruleType === 'ORDERS') {
            userLength = metric.orderUsers.length;
          } else if (rule.ruleType === 'REGISTRATION') {
            userLength = metric.registrationUsers.length;
          } else if (rule.ruleType === 'ACTIVATION') {
            userLength = metric.activationUsers.length;
          }
          return rule.incentiveAmountForReferred * userLength;
        }).filter((val: any) => val);
      } else {
        return [];
      }
    }).filter((val: any) => val).reduce((prev: any, next: any) => [...prev, ...next])
    .reduce((prev: any, next: any) =>  prev + next, 0);

    return amountSpentOnNewUser;
  }

  getCampaignStartDate = (startDate: any): any => {
    const campaignStartTimestamp = new Date(startDate).getTime();
    const enteredStartTimestamp = this.startDate.getTime();
    if (enteredStartTimestamp > campaignStartTimestamp) {
      return this.getDateFormatted(enteredStartTimestamp);
    }
    return this.getDateFormatted(campaignStartTimestamp);
  }

  getCampaignEndDate = (endDate: any): any => {
    const campaignEndTimestamp = new Date(endDate).getTime();
    const enteredEndTimestamp = this.endDate.getTime();
    if (enteredEndTimestamp > campaignEndTimestamp) {
      return this.getDateFormatted(campaignEndTimestamp);
    }
    return this.getDateFormatted(enteredEndTimestamp);
  }
}
