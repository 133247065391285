import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastMessage } from '../toast-message/toast-message.service';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { RejoiningBonusListPageService } from './rejoining-bonus-list-page.service';
import { AccessRightsService } from '../access-rights.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { ConfirmationDialogueService } from '../confirmation-dialogue/confirmation-dialogue.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'rejoining-bonus-list-page',
  template: require('./rejoining-bonus-list-page.component.html'),
  providers: [ToastMessage, RejoiningBonusListPageService]
})
export class RejoiningBonusListPageComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public numberOfPages: any;
  public currentPage: any;
  public newPage: any;
  public tableData: any = [];
  public dataSource = new MatTableDataSource([]);
  public screenBreakpoint = false;
  public displayedColumns: string[] = [];
  public limit = 10;
  public offset = 0;
  public noOfRejoiningBonus: number;
  public accessRights: any;
  public statuses = ['All', 'Active', 'Inactive']
  public status = new FormControl("All")
  public dataCardInfo = {
    name: 'Name',
    city: 'City',
    userSelector: 'User Selector',
    isActive: 'Status',
    tncs: 'Terms and Conditions',
    id: 'Rejoining Bonus ID',
    startDate: 'Start Date'
  };

  constructor(private router: Router,
    public dialog: MatDialog,
    private _accessRightsService: AccessRightsService,
    private _toastMessage: ToastMessage,
    private _joiningBonusListPageService: RejoiningBonusListPageService,
    private _confirmationDialogService: ConfirmationDialogueService) { }

  logout() {
    document.cookie = ' ';
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    this._toastMessage.openSnackBarSuccess('You have been logged out');
    document.cookie = 'token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    this.router.navigate(['/']);
  }

  showCampaignList() {
    this.router.navigate(['/campaign/list']);
  }

  showJoiningBonusList() {
    this.router.navigate(['/joiningBonus/list']);
  }

  addRejoiningBonus() {
    this.router.navigate(['/rejoiningBonus']);
  }

  getTableData() {
    this.tableData.forEach(rejoiningBonus => {
      rejoiningBonus.userSelectorNames = rejoiningBonus.userSelector.map(s => s.name)
      const services = rejoiningBonus.services;
      rejoiningBonus.serviceNames = !this.isEmpty(services) ? services.map(s => s.name) : ['N/A']
    });

    this.dataSource = this.tableData;
    this.dataSource.paginator = this.paginator;
  }

  setAccessRights() {
    this._accessRightsService.getUserAccessRights()
      .subscribe((res) => {
        this.accessRights = this.getAccessRights(res.data['data']);
        if (this.displayedColumns.includes('actions')) {
          if (this.accessRights && !this.accessRights.edit_campaign_access && !this.accessRights.campaign_performance) {
            const cols = this.displayedColumns.slice(0, -1);
            this.displayedColumns = cols;
          }
        }
      },
        err => {
          console.log('Unable to fetch user access rights ', err);
        });
  }

  fetchRejoiningBonusList(limit, offset, status) {
    this._joiningBonusListPageService.getListData(limit, offset, status.value)
      .subscribe(res => {
        this.tableData = res.body.result.rejoiningBonuses;
        this.noOfRejoiningBonus = res.body.result && res.body.result.totalCount ? res.body.result.totalCount : 5;
        if (this.tableData.length < 1) {
          this._toastMessage.openSnackBarError('No re-joining bonus found related to your search');
        }
        this.getTableData();
      },
        err => {
          console.log('An error occurred - ', err);
          const errorMessage = err && err.error && err.error.error && err.error.error.message ?
            err.error.error.message : 'Couldn\'t fetch data';
          this._toastMessage.openSnackBarError(errorMessage);
        });
  }

  sortData() {
    this.paginator.firstPage();
  }

  getAccessRights(data: string[]) {
    let temp = {};
    data.includes('edit_campaign_access') ? temp['edit_campaign_access'] = true : temp['edit_campaign_access'] = false;
    data.includes('campaign_performance') ? temp['campaign_performance'] = true : temp['campaign_performance'] = false;
    data.includes('view_referral_usage') ? temp['view_referral_usage'] = true : temp['view_referral_usage'] = false;
    data.includes('duplicate_campaign') ? temp['duplicate_campaign'] = true : temp['duplicate_campaign'] = false;
    data.includes('create_campaign') ? temp['create_campaign'] = true : temp['create_campaign'] = false;

    return temp;
  }

  pageChanged(evt: any) {
    this.limit = evt.pageSize;
    this.newPage = this.paginator.pageIndex;
    this.numberOfPages = Math.ceil(this.noOfRejoiningBonus / this.limit);
    if (evt.pageIndex + 1 === this.numberOfPages) {
      this.offset = (evt.pageIndex) * this.limit;
    } else if (evt.pageIndex === 0) {
      this.offset = 0;
    } else {
      this.offset = this.newPage >= this.currentPage ? this.offset + this.limit : this.offset - this.limit;
    }
    this.fetchRejoiningBonusList(this.limit, this.offset, this.status);
    this.currentPage = this.paginator.pageIndex;
  }

  deactivate(id) {
    this._confirmationDialogService.confirm('Caution', 'Once you deactivate it cannot be activated again. Do you want to continue?')
      .then((confirmed) => {
        if (confirmed) {
          this._joiningBonusListPageService.deactivate(id).subscribe(res => {
            if (res && res.body) {
              if (res.body.status && res.body.status === 'Success') {
                this._toastMessage.openSnackBarSuccess('Deactivated re-joining bonus');
                this.fetchRejoiningBonusList(this.limit, this.offset, this.status);
              } else {
                if (res.body.error && res.body.error.message) {
                  this._toastMessage.openSnackBarError(res.body.error.message);
                }
              }
            }
          }, err => {
            if (err && err.error && err.error.error && err.error.error.message) {
              this._toastMessage.openSnackBarError(err.error.error.message);
            } else {
              this._toastMessage.openSnackBarError('Something went wrong, please try again.');
            }
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  ngOnInit() {
    this.currentPage = 0;
    this.screenBreakpoint = (window['jQuery'](window).innerWidth() <= 760) ? true : false;
    this.displayedColumns = ['position', 'name', 'userSelectors', 'city', 'services', 'actions'];
    this.setAccessRights();
    this.fetchRejoiningBonusList(this.limit, this.offset, this.status);
  }

  onResize(event) {
    this.screenBreakpoint = (event.target.innerWidth <= 760) ? true : false;
    if (this.displayedColumns.includes('actions')) {
      this.displayedColumns = ['position', 'name', 'userSelectors', 'city', 'services', 'actions'];
    } else {
      this.displayedColumns = ['position', 'name', 'userSelectors', 'city', 'services'];
    }
  }

  openInfoCard(rejoiningBonusId) {
    this._joiningBonusListPageService.getRejoiningBonusInfo(rejoiningBonusId).subscribe(result => {
      if (result && result.body && result.body.status && result.body.status === 'Success') {
        const dataToShow = this.fetchDataToShow(result.body.result);
        this.openDialog(dataToShow);
      } else {
        this._toastMessage.openSnackBarError('Something went wrong. Please try again.');
      }
    }, error => {
      if (error && error.error && error.error.error && error.error.error.message) {
        this._toastMessage.openSnackBarError(error.error.error.message);
      } else {
        this._toastMessage.openSnackBarError('Something went wrong. Joining bonus data not found.');
      }
    });
  }

  fetchDataToShow(data) {
    const dataArray = [];
    const finalData = { title: '', data: '' };
    finalData.title = 'Joining Bonus Information';
    for (const key in data) {
      if (key && this.dataCardInfo[key]) {
        if (key === 'isActive') {
          dataArray.push('Status : ' + (data[key] ? 'Active' : 'Not Active'));
        } else if (key === 'userSelector') {
          dataArray.push(this.dataCardInfo[key] + ' : ' + data.userSelector.map(s => s.name).join(', '));
        } else {
          dataArray.push(this.dataCardInfo[key] + ' : ' + data[key] || 'N/A');
        }
      }
    }
    finalData.data = dataArray.join('\n');
    return finalData;
  }

  openDialog(data): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: 'fit-content',
      disableClose: true,
      hasBackdrop: true,
      data: { data }
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  isEmpty(value) {
    return (value === null || value.length === 0);
  }
}
