import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { ToastMessage } from '../toast-message/toast-message.service';
import { CampaignFormService } from '../campaign-form/campaign-form.service';
import { CampaignFormContent } from '../campaign-form/campaign-form-content';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'campaign-form',
  template: require('./campaign-form.component.html'),
  providers: [ToastMessage, CampaignFormService, CampaignFormContent]
})
export class CampaignFormComponent implements OnInit {
  // Validations
  eligibleCitiesFormControl = new FormControl('', [
    Validators.required
  ]);
  eligibleServicesFormControl = new FormControl('', [
    Validators.required
  ]);
  eligibleShiftsFormControl = new FormControl('', [
    Validators.required
  ]);


  @Output() incrementStepper: EventEmitter<any> = new EventEmitter();
  @Output() expandAll: EventEmitter<any> = new EventEmitter(true);
  @Output() collapseAll: EventEmitter<any> = new EventEmitter(true);
  @Input() data: any;
  @Input() cityMap: any;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() isEdit: Boolean;
  @Input() isViewOnly: Boolean;
  @Input() editStartDate: Boolean;
  @Input() editEndDate: Boolean;
  @Input() editCampainRunActivity: Boolean;
  @Input() createMode: Boolean;
  @Input() campaignData: any;
  @Input() editCampaignCode: Boolean;
  @Input() editReferralRules: Boolean;
  @Input() termsAndConditions: any;
  @Input() editTncs: Boolean;
  @Input() viewType: string
  
  @Input() role: any;
  // Variables
  minDate = new Date();
  minToDate = new Date();
  days: boolean[] = [];
  selectedDays: string[] = [];
  before = 'before';

  // when
  // startDate: Date;
  // endDate: Date;
  noOfDaysForCampaign: number;
  eligibleFor: string;
  formSubmitted = false;
  today: Date = new Date();
  currentCampaignId: any;
  whenSubmitted = false;

  // stepper array (to open/close accordian elements)
  stepper: string[] = ['when'];
  enabledStepper: string[] = ['when'];
  filledForm: string[] = [];
  expanding = false;

  // howToRun
  errMsg = 'Something went wrong';
  subRuleTyping = {perUser: 'PER_USER', cumulative: 'CUMULATIVE'};
  // Registration
  noOfRegistrationStages = 0;
  registrationArray = [];
  registrationArrayFinal = [];
  registrationCondition: string;

  // Activation
  noOfActivationStages = 0;
  activationArray = [];
  activationArrayFinal = [];
  activationCondition: string;

  // User Activity
  noOfUserActivityStages = 0;
  userActivityArray = [];
  eligibleForServices: string = 'No';
  selectedServices: string[] = [];
  userActivityArrayFinal = [];

  // Messages
  shortDisplayMessage: string;
  longDisplayMessage: string;
  successMessageToReferred: string;
  failureMessageToReferred: string;
  successMessageToReferrer: string;

  // code
  campaignCode: string;
  campaignName: string;
  enteredAndValidatedCode: string;
  validatedCode: Boolean = false;

  subRuleTypeMap = {PER_USER: 'perUser', CUMULATIVE: 'cumulative'};
  // walletList: string[] = ['Rapido Wallet', 'Coins'];
  paymentTypes = {
    wallet: "Rapido Money",
    coins: 'Coins'
  }
  benefitsFor: string[] = ['Each new user', 'Every 10 Kms', 'Every 5 rides'];
  userActivityList: string[] = ['login hours', 'rides', 'kilometers'];
  serviceList: string[] = [];
  servicesFetched: Record<string, string[]> = {};
  selectedCities: string[];
  shiftList: string[] = ['Shift1', 'Shift2', 'Shift3'];
  selectedShifts: string[];

  constructor(private _toastMessage: ToastMessage,
    private _campaignFormService: CampaignFormService,
    public content: CampaignFormContent,
    private router: Router,
    private datePipe: DatePipe) { }

  ngOnInit() {
    this.fetchServices();
    // To expand the How to run this campaign panel on edit mode
    if (this.isEdit || this.viewType === 'View') {
      this.enabledStepper.push('how', 'when', 'tncs', 'code');
      this.campaignCode = this.campaignData.campaignCode;
      this.getReferralRules();
    }
  }

  getReferralRules = () => {
    const campaign = this.campaignData;
    if(campaign.referralRules && campaign.referralRules.length > 0) {
      campaign.referralRules.map((rule : any, id: any) => {
        if(rule.ruleType === "REGISTRATION") {
          this.registrationArray.push({
            ruleType : 'registration',
            quantityMax : rule.maxValue,
            paymentType: this.paymentTypes[rule.incentiveType],
            referredIncentive : {
              incentiveAmount : rule.incentiveAmountForReferred,
              payment:{
                validity: {
                  value: rule.validityReferredCoins ? rule.validityReferredCoins.value : ''
                }
              }
            },
            referrerIncentive : {
              incentiveAmount : rule.incentiveAmountForReferrer,
              payment:{
                validity: {
                  value: rule.validityReferrerCoins ? rule.validityReferrerCoins.value : ''
                }
              }
            }
          });

          this.registrationCondition = this.subRuleTypeMap[rule.subRuleType];
        }
        else if(rule.ruleType === "ORDERS") {
          if (Array.isArray(rule.serviceDetails)) {
            this.eligibleForServices = rule.serviceDetails.length ? 'Yes' : 'No'
            const activeServiceNames = [...new Set<string>(rule.serviceDetails.map(serviceDetail => serviceDetail.name))]
            this.serviceList = activeServiceNames
            this.selectedServices = activeServiceNames
          } else {
            this.eligibleForServices = 'No'
          }
          this.userActivityArray.push({
            ruleType : 'user activity',
            expiryInDays : rule.expiryInDays,
            quantityMin : rule.minValue,
            quantityMax : rule.maxValue,
            paymentType: this.paymentTypes[rule.incentiveType],
            serviceDetails: rule.serviceDetails,
            referredIncentive : {
              incentiveAmount : rule.incentiveAmountForReferred,
              payment:{
                validity: {
                  value: rule.validityReferredCoins ? rule.validityReferredCoins.value : ''
                }
              }
            },
            referrerIncentive : {
              incentiveAmount : rule.incentiveAmountForReferrer,
              payment:{
                validity: {
                  value: rule.validityReferrerCoins ? rule.validityReferrerCoins.value : ''
                }
              }
            }
          });
        }
        else if(rule.ruleType === "ACTIVATION") {
          this.activationArray.push({
            ruleType : 'activation',
            quantityMax : rule.maxValue,
            expiryInDays : rule.expiryInDays,
            paymentType: this.paymentTypes[rule.incentiveType],
            referredIncentive : {
              incentiveAmount : rule.incentiveAmountForReferred,
              payment:{
                type: '',
                validity: {
                  value: ''
                }
              }
            },
            referrerIncentive : {
              incentiveAmount : rule.incentiveAmountForReferrer,
              payment:{
                validity: {
                  value: ''
                }
              }
            }
          });
          this.activationCondition = this.subRuleTypeMap[rule.subRuleType];
        }
      })
    }
    this.noOfActivationStages = this.activationArray && this.activationArray.length;
    this.noOfRegistrationStages = this.registrationArray && this.registrationArray.length;
    this.noOfUserActivityStages = this.userActivityArray && this.userActivityArray.length;
  }

  resetEligibleDetails() {
    this.selectedCities = [];
    this.selectedServices = [];
    this.selectedShifts = [];
    this.eligibleFor = null;
  }

  clearSelectedServices() {
    this.selectedServices = [];
  }

  changeInStartDate() {
    this.minToDate = this.startDate;
    // new Date((new Date(this.startDate)).setDate(new Date(this.startDate).getDate() + 1));
    if (this.endDate && this.startDate) {
      const noOfDays = (this.endDate.valueOf() - this.startDate.valueOf()) / (1000 * 60 * 60 * 24);
      this.noOfDaysForCampaign = noOfDays + 1;
    } else if (!this.endDate && this.noOfDaysForCampaign && this.startDate) {
      const newEndDate = new Date(this.startDate.getTime() + ((this.noOfDaysForCampaign - 1) * 1000 * 60 * 60 * 24));
      this.endDate = newEndDate;
    }
  }

  changeInNoOfDays() {
    if (!this.startDate) {
      this._toastMessage.openSnackBarError('Please enter a start date');
    } else {
      const newEndDate = new Date(this.startDate.getTime() + ((this.noOfDaysForCampaign) * 1000 * 60 * 60 * 24));
      this.endDate = newEndDate;
    }
    if (this.noOfDaysForCampaign <= 0) {
      this._toastMessage.openSnackBarError('Please enter a valid date');
    }
  }

  changeInEndDate() {
    if (!this.startDate) {
      this._toastMessage.openSnackBarError('Please enter a start date');
    } else if (!this.endDate) {
      this.endDate = this.startDate;
      this.noOfDaysForCampaign = 1;
    } else {
      const noOfDays = (this.endDate.valueOf() - this.startDate.valueOf()) / (1000 * 60 * 60 * 24);
      this.noOfDaysForCampaign = noOfDays + 1;
    }
  }

  submitCampaignWhenDetails() {
    if (!this.startDate || !this.endDate) {
      this._toastMessage.openSnackBarError('Please enter both the dates.');
    } else if (this.startDate > this.endDate) {
      this._toastMessage.openSnackBarError('The \'to\' date cannot be before the \'from\' date');
    } else {
      //  var sendStartDate = this.datePipe.transform(new Date(this.startDate),"yyyy-MM-dd");
      //  var sendEndDate = this.datePipe.transform(new Date(this.endDate),"yyyy-MM-dd");

      const sendStartDate = new Date(this.startDate).getTime();
      const sendEndDate = new Date(this.endDate).getTime();
      const campaignId = this.isEdit ? this.campaignData.campaignId : this.data._id;

      this._campaignFormService.submitReferralWhenDetails(sendStartDate, sendEndDate, campaignId).subscribe(res => {
        // console.log('res (when) : ', res);
        this.stepper = ['how'];
        this.whenSubmitted = true;
        if (this.enabledStepper.indexOf('how') < 0) {
          this.enabledStepper.push('how');
        }
        if (this.filledForm.indexOf('when') < 0) {
          this.filledForm.push('when');
        }
        this.incrementStepper.emit(30);
        this._toastMessage.openSnackBarSuccess('Submitted end date and start date');
      },
        err => {
          console.log('err (when) : ', err);
          this._toastMessage.openSnackBarError(err && err.error && err.error.error && err.error.error.message ?
            err.error.error.message : 'Something went wrong');
        });
    }
  }

  changeInRegistrationCondition() {
    if (this.registrationCondition === 'perUser') {
      if (this.noOfRegistrationStages > 1) {
        while (this.noOfRegistrationStages !== 1) {
          this.registrationArray.pop();
          this.noOfRegistrationStages--;
        }
      }
    }
  }

  changeInActivationCondition() {
    if (this.activationCondition === 'perUser') {
      if (this.noOfActivationStages > 1) {
        while (this.noOfActivationStages !== 1) {
          this.activationArray.pop();
          this.noOfActivationStages--;
        }
      }
    }
  }

  isInt(n) {
    return n % 1 === 0;
 }

 fetchServices() {
  this.serviceList = [];
  this.servicesFetched = {};
  const cityIds = this.data && this.data.cities.map(city => this.cityMap[city]) || []
  if (cityIds.length) {
    this._campaignFormService.getServicesList(cityIds).subscribe(res => {
      const allServices = res.data;
      for (const service in allServices) {
        if (service) {
          const serviceName = allServices[service] && allServices[service]['service']
            && allServices[service]['service']['name'];
          if (this.servicesFetched[serviceName]) {
            this.servicesFetched[serviceName].push(allServices[service]["_id"])
          } else {
            this.servicesFetched[serviceName] = [allServices[service]["_id"]]
          }
        }
      }
      this.serviceList = Object.keys(this.servicesFetched);
    }, () => {
      this._toastMessage.openSnackBarError('Unable to fetch services.');
    });
  }
}

  validateRegistrationStages() {
    if (this.noOfRegistrationStages === 0 || this.registrationCondition) {
      let checkedReg = 0;
      if (this.noOfRegistrationStages > 0) {
        for (let i = 0; i < this.registrationArray.length; i++) {
          if (this.registrationCondition === 'cumulative' && (isNaN(this.registrationArray[i].quantityMax) ||
                this.isEmpty(this.registrationArray[i].quantityMax))) {
            this.errMsg = 'Please enter a positive value for min registrations in the registration stage';
            this._toastMessage.openSnackBarError(this.errMsg);
            throw new Error(this.errMsg);
          // } else if (this.isEmpty(this.registrationArray[i].referrerIncentive['incentiveAmount']) ||
          //             this.registrationArray[i].referrerIncentive['incentiveAmount'] <= 0) {
          //               // !this.registrationArray[i].referredIncentive['incentiveAmount'] ||
          //             // this.registrationArray[i].referredIncentive['incentiveAmount'] <= 0 ||
          //   this.errMsg = 'Please fill in the amount of payments (greater than 0) for the users in the registration stage';
          //   this._toastMessage.openSnackBarError(this.errMsg);
          //   throw new Error(this.errMsg);
          } else if (!this.registrationArray[i].paymentType) {
            this.errMsg = 'Please fill in the mode of payments for both the users in the registration stage';
            this._toastMessage.openSnackBarError(this.errMsg);
            throw new Error(this.errMsg);
          } else if (this.registrationArray[i].paymentType == this.paymentTypes.coins &&
            (!this.registrationArray[i].referredIncentive.payment.validity.value || !this.registrationArray[i].referrerIncentive.payment.validity.value)) {
            this.errMsg = 'Please fill in the validity of coins';
            this._toastMessage.openSnackBarError(this.errMsg);
            throw new Error(this.errMsg);
          }else if (!(this.isInt(this.registrationArray[i].referredIncentive['incentiveAmount']) &&
                      this.isInt(this.registrationArray[i].referrerIncentive['incentiveAmount']) &&
                      this.isInt(this.registrationArray[i].quantityMax))) {
            this.errMsg = 'Please enter only integers (no decimals) in the registration stage';
            this._toastMessage.openSnackBarError(this.errMsg);
            throw new Error(this.errMsg);
          } else {
            checkedReg++;
          }
        }
        return checkedReg;
      } else {
        return checkedReg;
      }
    } else {
      return 'error';
    }
  }

  validateActivationStages() {
    if (this.noOfActivationStages === 0 || this.activationCondition) {
      let checkedAct = 0;
      if (this.noOfActivationStages > 0) {
        for (let i = 0; i < this.activationArray.length; i++) {
          if (this.activationCondition === 'cumulative' && (isNaN(this.activationArray[i].quantityMax) ||
                this.isEmpty(this.activationArray[i].quantityMax))) {
            this.errMsg = 'Please enter a positive value for min activations in the activation stage';
            this._toastMessage.openSnackBarError(this.errMsg);
            throw new Error(this.errMsg);
          // } else if (!this.activationArray[i].expiryInDays) {
          //   this.errMsg = 'No. of days is missing in the activation stage';
          //   this._toastMessage.openSnackBarError(this.errMsg);
          //   throw new Error(this.errMsg);
          // } else if (this.isEmpty(this.activationArray[i].referrerIncentive['incentiveAmount']) ||
          //             this.activationArray[i].referrerIncentive['incentiveAmount'] <= 0) {
          //             // !this.activationArray[i].referredIncentive['incentiveAmount'] ||
          //             // this.activationArray[i].referredIncentive['incentiveAmount'] <= 0) {
          //   this.errMsg = 'Please fill in the amount of payments (greater than 0) for the users in the activation stage';
          //   this._toastMessage.openSnackBarError(this.errMsg);
          //   throw new Error(this.errMsg);
          } else if (!this.activationArray[i].paymentType) {
            this.errMsg = 'Please fill in the mode of payments for the users in the activation stage';
            this._toastMessage.openSnackBarError(this.errMsg);
            throw new Error(this.errMsg);
          } else if (this.activationArray[i].paymentType == this.paymentTypes.coins &&
            (!this.activationArray[i].referredIncentive.payment.validity.value || !this.activationArray[i].referrerIncentive.payment.validity.value)) {
            this.errMsg = 'Please fill in the validity of coins';
            this._toastMessage.openSnackBarError(this.errMsg);
            throw new Error(this.errMsg);
          } else if (!(this.isInt(this.activationArray[i].referredIncentive['incentiveAmount']) &&
            this.isInt(this.activationArray[i].referrerIncentive['incentiveAmount']) &&
            this.isInt(this.activationArray[i].quantityMax))) {
            this.errMsg = 'Please enter only integers (no decimals) in the Activation stage';
            this._toastMessage.openSnackBarError(this.errMsg);
            throw new Error(this.errMsg);
          } else {
            checkedAct++;
          }
        }
        return checkedAct;
      } else {
        return checkedAct;
      }
    } else {
      return 'error';
    }
  }

  validateUserActivityStages() {
    let checkedUserAct = 0;
    if (this.noOfUserActivityStages > 0) {
      for (let i = 0; i < this.userActivityArray.length; i++) {
        if (!this.userActivityArray[i].quantityMax ||  this.isEmpty(this.userActivityArray[i].quantityMax)) {
          this.errMsg = 'Please select limit for the user activity stage';
          this._toastMessage.openSnackBarError(this.errMsg);
          throw new Error(this.errMsg);
        // } else if (!this.userActivityArray[i].expiryInDays) {
        //   this.errMsg = 'No. of days is missing in the user activity stage';
        //   this._toastMessage.openSnackBarError(this.errMsg);
        //   throw new Error(this.errMsg);
        // } else if (!this.userActivityArray[i].referrerIncentive['incentiveAmount'] ||
        //             this.userActivityArray[i].referrerIncentive['incentiveAmount'] <= 0) {
        //               // !this.userActivityArray[i].referrerIncentive['incentiveAmount'] ||
        //               // this.userActivityArray[i].referrerIncentive['incentiveAmount'] <= 0
        //   this.errMsg = 'Please fill in the amount of payments (greater than 0) for the users in the user activity stage';
        //   this._toastMessage.openSnackBarError(this.errMsg);
        //   throw new Error(this.errMsg);
        } else if (!this.userActivityArray[i].paymentType) {
          this.errMsg = 'Please fill in the mode of payments for the users in the user activity stage';
          this._toastMessage.openSnackBarError(this.errMsg);
          throw new Error(this.errMsg);
        } else if (this.userActivityArray[i].paymentType == this.paymentTypes.coins &&
          (!this.userActivityArray[i].referredIncentive.payment.validity.value || !this.userActivityArray[i].referrerIncentive.payment.validity.value)) {
          this.errMsg = 'Please fill in the validity of coins';
          this._toastMessage.openSnackBarError(this.errMsg);
          throw new Error(this.errMsg);
        } else if (!(this.isInt(this.userActivityArray[i].referredIncentive['incentiveAmount']) &&
            this.isInt(this.userActivityArray[i].referrerIncentive['incentiveAmount']) &&
            this.isInt(this.userActivityArray[i].quantityMax) && this.isInt(this.userActivityArray[i].expiryInDays))) {
          this.errMsg = 'Please enter only integers (no decimals) in the User Activity stage #' + (i + 1);
          this._toastMessage.openSnackBarError(this.errMsg);
          throw new Error(this.errMsg);
        } else {
          checkedUserAct++;
        }
      }
      return checkedUserAct;
    } else {
      return checkedUserAct;
    }
  }

  addRegistrationStage() {
    if (this.noOfRegistrationStages === 1 && !this.registrationCondition) {
      this._toastMessage.openSnackBarError('Please select the type for registration stage(s)');
    } else {
      const validatedRegStages = this.validateRegistrationStages();
      if (validatedRegStages === this.noOfRegistrationStages) {
        this.noOfRegistrationStages++;
        this.registrationArray.push({
          ruleType : 'registration',
          quantityMax : '',
          selectedCities : '',
          paymentType: '',
          referredIncentive : {
            incentiveAmount : '',
            payment:{
              validity: {
                value: ''
              }
            }
          },
          referrerIncentive : {
            incentiveAmount : '',
            payment:{
              validity: {
                value: ''
              }
            }
          }
        });
      }
    }
  }

  addActivationStage() {
    if (this.noOfActivationStages === 1 && !this.activationCondition) {
      this._toastMessage.openSnackBarError('Please select the type for activation stage(s)');
    } else {
      const validatedActStages = this.validateActivationStages();
      if (validatedActStages === this.noOfActivationStages) {
        this.noOfActivationStages++;
        this.activationArray.push({
          ruleType : 'activation',
          quantityMax : '',
          expiryInDays : '',
          selectedCities : '',
          paymentType: '',
          referredIncentive : {
            incentiveAmount : '',
            payment:{
              validity: {
                value: ''
              }
            }
          },
          referrerIncentive : {
            incentiveAmount : '',
            payment:{
              validity: {
                value: ''
              }
            }
          }
        });
      }
    }
  }

  addUserActivityStage() {
    const validatedUserActStages = this.validateUserActivityStages();
    if (validatedUserActStages === this.noOfUserActivityStages) {
      this.noOfUserActivityStages++;
      this.userActivityArray.push({
        ruleType : 'user activity',
        expiryInDays : '',
        quantityMin : '',
        quantityMax : '',
        selectedCities : '',
        paymentType: '',
        referredIncentive : {
          incentiveAmount : '',
          payment:{
            validity: {
              value: ''
            }
          }
        },
        referrerIncentive : {
          incentiveAmount : '',
          payment:{
            validity: {
              value: ''
            }
          }
        }
      });
    }
  }

  removeRegistrationStage(index) {
    const x = confirm('Are you sure you want to remove this registration stage?');
    if (x) {
      this.noOfRegistrationStages--;
      this.registrationArray.splice(index, 1);
    }
  }

  removeActivationStage(index) {
    const x = confirm('Are you sure you want to remove this activation stage?');
    if (x) {
      this.noOfActivationStages--;
      this.activationArray.splice(index, 1);
    }

  }

  removeUserActivityStage(index) {
    const x = confirm('Are you sure you want to remove this activity stage?');
    if (x) {
      this.noOfUserActivityStages--;
      this.userActivityArray.splice(index, 1);
    }
  }

  isEmpty(value) {
    return (value === null || value.length === 0);
  }

  submitCampaignHowDetails() {
    if (this.noOfActivationStages === 0 && this.noOfRegistrationStages === 0 && this.noOfUserActivityStages === 0) {
      this._toastMessage.openSnackBarError('Please enter at least one stage');
    } else {
      // Registration Validations
      let checkedReg: any = 0;
      if (this.noOfRegistrationStages > 0) {
        checkedReg = this.validateRegistrationStages();
        if (checkedReg === 'error') {
          this._toastMessage.openSnackBarError('Please select the type for registration stage(s)');
        }
        if (this.registrationCondition === 'perUser') {
          this.registrationArray[0].quantityMax = 0;
        }
      }

      // Activation Validation
      if (checkedReg === this.registrationArray.length) {
        var checkedAct: any = 0;
        if (this.noOfActivationStages > 0) {
          checkedAct = this.validateActivationStages();
          if (checkedAct === 'error') {
            this._toastMessage.openSnackBarError('Please select the type for activation stage(s)');
          }
          if (this.activationCondition === 'perUser') {
            this.activationArray[0].quantityMax = 0;
          }
        }
      }

      // User Activity Validation
      if (checkedAct === this.activationArray.length) {
        var checkedUserAct: any = 0;
        if (this.noOfUserActivityStages > 0) {
          checkedUserAct = this.validateUserActivityStages();
        }
      }

      // formatting for api request
      if (checkedUserAct === this.userActivityArray.length) {
        this.createFinalArray();
      }
    }
  }

  createFinalArray() {
    const finalArray = [];
    let finalRegStates = 0;
    let finalActStates = 0;
    let finalOrderStates = 0;
    if (this.registrationArray && this.registrationArray.length > 0) {
      for (const regRule in this.registrationArray) {
        if (this.registrationArray[regRule]) {
          const info = this.registrationArray[regRule];
          finalArray.push({
            'ruleType' : 'REGISTRATION',
            'subRuleType' : this.subRuleTyping[this.registrationCondition],
            // 'expiryInDays' : info.expiryInDays ? info.expiryInDays : 0,
            'maxValue' : info.quantityMax ? info.quantityMax : 0,
            'incentiveAmountForReferrer' : info.referrerIncentive && info.referrerIncentive.incentiveAmount ?
                                          info.referrerIncentive.incentiveAmount : 0,
            'incentiveAmountForReferred' : info.referredIncentive && info.referredIncentive.incentiveAmount ?
                                          info.referredIncentive.incentiveAmount : 0,
            referrer:{
              payment: {
                type: Object.keys(this.paymentTypes).find(key => info.paymentType == this.paymentTypes[key]),
                validity:{
                  value: info.referrerIncentive.payment.validity.value,
                  unit: 'days'
                }
              }
            },
            referred:{
              payment: {
                type: Object.keys(this.paymentTypes).find(key => info.paymentType == this.paymentTypes[key]),
                validity:{
                  value: info.referredIncentive.payment.validity.value,
                  unit: 'days'
                }
              }
            }
          });
          finalRegStates++;

        }
      }
    }

    if (this.activationArray && this.activationArray.length > 0) {
      for (const actRule in this.activationArray) {
        if (this.activationArray[actRule]) {
          const info = this.activationArray[actRule];
          finalArray.push({
            'ruleType' : 'ACTIVATION',
            'subRuleType' : this.subRuleTyping[this.activationCondition],
            // 'expiryInDays' : info.expiryInDays ? info.expiryInDays : 0,
            'maxValue' : info.quantityMax ? info.quantityMax : 0,
            'incentiveAmountForReferrer' : info.referrerIncentive && info.referrerIncentive.incentiveAmount ?
                                          info.referrerIncentive.incentiveAmount : 0,
            'incentiveAmountForReferred' : info.referredIncentive && info.referredIncentive.incentiveAmount ?
                                          info.referredIncentive.incentiveAmount : 0,
            referrer:{
              payment: {
                type: Object.keys(this.paymentTypes).filter(key => info.paymentType == this.paymentTypes[key]),
                validity:{
                  value: info.referrerIncentive.payment.validity.value,
                  unit: 'days'
                }
              }
            },
            referred:{
              payment: {
                type: Object.keys(this.paymentTypes).filter(key => info.paymentType == this.paymentTypes[key]),
                validity:{
                  value: info.referredIncentive.payment.validity.value,
                  unit: 'days'
                }
              }
            }
          });
          finalActStates++;
        }
      }
    }

    if (this.userActivityArray && this.userActivityArray.length > 0) {
      for (const userRule in this.userActivityArray) {
        if (this.userActivityArray[userRule]) {
          const info = this.userActivityArray[userRule];
          finalArray.push({
            'ruleType' : 'ORDERS',
            'subRuleType' : 'PER_USER',
            'expiryInDays' : info.expiryInDays ? info.expiryInDays : 0,
            'maxValue' : info.quantityMax ? info.quantityMax : 0,
            'incentiveAmountForReferrer' : info.referrerIncentive && info.referrerIncentive.incentiveAmount ?
                                          info.referrerIncentive.incentiveAmount : 0,
            'incentiveAmountForReferred' : info.referredIncentive && info.referredIncentive.incentiveAmount ?
                                          info.referredIncentive.incentiveAmount : 0,
            serviceDetails: this.selectedServices
              .map(serviceName => {
                if (this.servicesFetched[serviceName] && Array.isArray(this.servicesFetched[serviceName])) {
                  return this.servicesFetched[serviceName].map(id => ({name: serviceName, _id: id}))
                }
              })
              .reduce((acc, val) => {
                if (val) {
                  acc.push(...val)
                }
                return acc
              }, []),
            referrer:{
              payment: {
                type: Object.keys(this.paymentTypes).filter(key => info.paymentType == this.paymentTypes[key]),
                validity:{
                  value: info.referrerIncentive.payment.validity.value,
                  unit: 'days'
                }
              }
            },
            referred:{
              payment: {
                type: Object.keys(this.paymentTypes).filter(key => info.paymentType == this.paymentTypes[key]),
                validity:{
                  value: info.referredIncentive.payment.validity.value,
                  unit: 'days'
                }
              }
            }
          });
          finalOrderStates++;
        }
      }
    }
    // console.log('HERE\'S THE FINAL ONE ? ', finalArray);
    if (this.role === 'CUSTOMER' && !finalRegStates) {
      finalArray.push({
        'ruleType' : 'REGISTRATION',
        'subRuleType' : 'PER_USER',
        'maxValue' : 0,
        'incentiveAmountForReferrer' : 0,
        'incentiveAmountForReferred' : 0
      });
    } else if (this.role === 'RIDER' && !finalActStates) {
      finalArray.push({
        'ruleType' : 'ACTIVATION',
        'subRuleType' : 'PER_USER',
        'maxValue' : 0,
        'incentiveAmountForReferrer' : 0,
        'incentiveAmountForReferred' : 0
      });
    }
    this.apiSubmitHow(finalArray);
  }

  apiSubmitHow(array) {

    let campaignId: any = this.data._id;
    if (this.isEdit && this.campaignData && this.campaignData.campaignId) {
      campaignId = this.campaignData.campaignId;
    }

    this._campaignFormService.submitReferralHowDetails(array, campaignId).subscribe(res => {
      this.stepper = ['tncs'];
      if (this.enabledStepper.indexOf('tncs') < 0) {
        this.enabledStepper.push('tncs');
      }
      this.incrementStepper.emit(70);
      this._toastMessage.openSnackBarSuccess('Successfully submitted rules');
    },
    (err: any) => {
      // console.log('err (how) : ', err);
    });
  }

  resetCampaignHowDetails() {
    this.registrationArray = [];
    this.registrationArrayFinal = [];
    this.activationArray = [];
    this.activationArrayFinal = [];
    this.userActivityArray = [];
    this.userActivityArrayFinal = [];
    this.noOfActivationStages = 0;
    this.noOfRegistrationStages = 0;
    this.noOfUserActivityStages = 0;
  }

  submitCampaignMessagesDetails() {
    if (!this.shortDisplayMessage || this.shortDisplayMessage.trim().length === 0) {
      this._toastMessage.openSnackBarError('Please enter the short message');
    } else if (!this.longDisplayMessage || this.longDisplayMessage.trim().length === 0) {
      this._toastMessage.openSnackBarError('Please enter the long message');
    } else if (!this.successMessageToReferred || this.successMessageToReferred.trim().length === 0) {
      this._toastMessage.openSnackBarError('Please enter the success message for new user');
    } else if (!this.failureMessageToReferred || this.failureMessageToReferred.trim().length === 0) {
      this._toastMessage.openSnackBarError('Please enter the failure message for new user');
    } else if (!this.successMessageToReferrer || this.successMessageToReferrer.trim().length === 0) {
      this._toastMessage.openSnackBarError('Please enter the success message for old user');
    } else {
      this.incrementStepper.emit(90);
      this.shortDisplayMessage = this.shortDisplayMessage.trim();
      this.longDisplayMessage = this.longDisplayMessage.trim();
      this.successMessageToReferred = this.successMessageToReferred.trim();
      this.failureMessageToReferred = this.failureMessageToReferred.trim();
      this.successMessageToReferrer = this.successMessageToReferrer.trim();
      this._campaignFormService.submitReferralMessageDetails(this.shortDisplayMessage, this.longDisplayMessage,
          this.successMessageToReferred, this.failureMessageToReferred, this.successMessageToReferrer, this.data._id).subscribe(res => {
        // console.log('res (messages) : ', res);
      },
        err => {
          // console.log('err (messages) : ', err);
        });
      this.stepper = ['code'];
      if (this.enabledStepper.indexOf('code') < 0) {
        this.enabledStepper.push('code');
      }
      if (this.filledForm.indexOf('messages') < 0) {
        this.filledForm.push('messages');
      }
    }
  }

  checkCodeAvailability() {
    if (!this.campaignCode || this.campaignCode.trim().length === 0) {
      this._toastMessage.openSnackBarError('Enter a code');
    } else if (this.campaignCode.length !== 7) {
      this._toastMessage.openSnackBarError('Code must be 7 characters long');
    } else {
      this.campaignCode = this.campaignCode.trim().toUpperCase();
      this._campaignFormService.checkReferralCodeAvailability(this.campaignCode, this.data.id).subscribe(res => {
        // console.log('res (code availability) : ', res);
        if (!res.body.result.isValid) {
          this._toastMessage.openSnackBarError('The code is already taken');
        } else if (res.body.result.isValid) {
          this._toastMessage.openSnackBarSuccess('This code is available');
          this.validatedCode = true;
        }
      },
      err => {
        // console.log('err (code availability) : ', err);
        this.enteredAndValidatedCode = this.campaignCode;
        this._toastMessage.openSnackBarSuccess(err && err.error && err.error.error && err.error.error.message ? err.error.error.message :
          'Something went wrong. Please try again.');
      });
    }
  }

  enableSubmitButtons() {
    if (/^[a-z0-9]+$/i.test(this.campaignCode)) {
      if (this.campaignCode !== this.enteredAndValidatedCode) {
        this.validatedCode = false;
      } else {
        this.validatedCode = true;
      }
    } else {
      this.validatedCode = false;
      this._toastMessage.openSnackBarError('Only alphanumeric characters are allowed');
    }
  }

  submitCampaignCodeDetails(activate) {
    if (!this.campaignCode || this.campaignCode.trim().length === 0 && !this.isEdit) {
      this._toastMessage.openSnackBarError('Please enter a code');
    } else if (!this.validatedCode && !this.isEdit) {
      this._toastMessage.openSnackBarError('Please check whether the code is available or not');
    // } else if (!this.campaignName) {
    //   this._toastMessage.openSnackBarError('Please enter a name for this campaign');
    } else {
      this.campaignCode = this.campaignCode.trim();
      let campaignId: any = this.data._id;
      if (this.isEdit && this.campaignData && this.campaignData.campaignId) {
         campaignId = this.campaignData.campaignId;
      }
      this._campaignFormService.submitReferralCodeDetails(this.campaignCode, activate, campaignId).subscribe(res => {
        // console.log('res (code) : ', res);
        this._toastMessage.openSnackBarSuccess('Code has been submitted successfully');
        this.filledForm.push('code');
        this.stepper = [];
        this.incrementStepper.emit(100);
        this.formSubmitted = true;
        setTimeout(() => {
          this.router.navigate(['/campaign/list']);
        }, 1000);
      },
      err => {
        console.log('err (code) : ', err);
        this._toastMessage.openSnackBarError(err && err.error && err.error.error && err.error.error.message ? err.error.error.message : 'Something went wrong!');
      });
    }
  }

  // Handling accordian states

  expandAllAccordians() {
    this.expandAll.emit();
    this.stepper = [];
    this.stepper = this.enabledStepper;
  }

  collapseAllAccordians() {
    this.stepper = [];
    this.collapseAll.emit();
  }

  expansionPanelOpen(evt, stage) {
    if (this.stepper.indexOf(stage) < 0) {
      this.stepper = [stage];
    }
    //  if(this.stepper.length <= 2) {
    //    this.collapseAll.emit();
    //  }
  }

  expansionPanelClose(evt, stage) {
    const tempEnabled = JSON.stringify(this.enabledStepper);
    if (this.stepper.indexOf(stage) > -1) {
     this.stepper.splice(this.stepper.indexOf(stage), 1);
    }
    this.enabledStepper = JSON.parse(tempEnabled);
  }

  onSubmitTncs() {
    // Split terms and conditions by (;)
    const tncs: any = this.termsAndConditions;
    if (!tncs || tncs.trim().length === 0) {
      return this._toastMessage.openSnackBarError('Please enter a terms and condtions of the campaign.');
    }
    const termsAndConditions: any = tncs.split(';').map((term: any) => term.trim()).filter((term: any) => term);

    const campaignId: any = this.isEdit ? this.campaignData.campaignId : this.data._id;

    this._campaignFormService.submitTermsAndConditions(termsAndConditions, campaignId).subscribe((res: any) => {
      this.stepper = ['code'];
      if (this.enabledStepper.indexOf('code') < 0) {
        this.enabledStepper.push('code');
      }
      this._toastMessage.openSnackBarSuccess('Updated terms and conditions.');
    },
      (err: any) => {
        const errorMessage = err && err.error && err.error.error && err.error.error.message ?
          err.error.error.message : 'Something went wrong.';
        this._toastMessage.openSnackBarError(errorMessage === 'Unable to update Campaign Display Messages' ?
          'Unable to update terms and conditions.' : errorMessage);

      });
  }

  getReferralRuleEditStatus() {
    if (this.isEdit) {
      return !this.editReferralRules;
    }
    return ((this.noOfActivationStages + this.noOfRegistrationStages + this.noOfUserActivityStages) === 0);
  }

  getEditCampaignCodeStatus() {
    if (this.isEdit) {
      return !this.editCampaignCode;
    }
    return !this.validatedCode;
  }

  getAddRegistrationStageStatus () {
    if(this.registrationArray && this.registrationArray.length>0) {
      return true;
    } else if(this.isEdit && this.registrationArray && this.registrationArray.length === 0) {
      return !this.editCampainRunActivity;
    } else {
      return this.noOfRegistrationStages > 0;
    }
  }

  getWalletList(role){
    if(role == 'CUSTOMER'){
      return [this.paymentTypes.coins]
    }
    return [this.paymentTypes.wallet]
  }

  isDisabled(){
   return this.viewType === 'View'
  }

  onNext(){
    this.expandAllAccordians();
    this.enabledStepper.push('how', 'when', 'tncs', 'code');
    this.incrementStepper.emit(99);
  }
}