import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ToastMessage } from '../toast-message/toast-message.service';
import {MatPaginator, MatTableDataSource, MatSort, Sort} from '@angular/material';
import { CampaignListPageService } from '../campaign-list-page/campaign-list-page.service';
import { AccessRightsService } from '../access-rights.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'campaign-list-page',
  template: require('./campaign-list-page.component.html'),
  providers: [ToastMessage, CampaignListPageService]
})
export class CampaignListPageComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public numberOfPages: any;
  public currentPage: any;
  public newPage: any;
  public tableData: any = [];
  public dataSource = new MatTableDataSource([]);
  public screenBreakpoint = false;
  public displayedColumns: string[];
  public limit = 10;
  public offset = 0;
  public code: string = null;
  public name: string = null;
  public city: string = null;
  public service: string = null;
  public shift: string = null;
  public role: string;
  public noOfCampaigns: number;
  public accessRights: any;
  public statuses = ['All', 'Active', 'Inactive']
  public status = new FormControl("All")
  public dataCardInfo = {
    description: 'Description',
    startDate: 'Start Date',
    endDate: 'End Date',
    cities: 'Cities',
    code: 'Code',
    isActive: 'Status',
    role: 'Campaign is applicable for',
    tncs: 'Terms and Conditions',
    id: 'Campaign ID'
  };

  monthMap = {
    1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun',
    7: 'Jul', 8: 'Aug', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dec'
  };
  constructor(private router: Router,
    public dialog: MatDialog,
    private _accessRightsService: AccessRightsService,
    private breakpointObserver: BreakpointObserver,
    private _toastMessage: ToastMessage,
    private _campaignListPageService: CampaignListPageService) { }

    logout() {
      document.cookie = ' ';
      localStorage.removeItem('userId');
      localStorage.removeItem('token');
      localStorage.removeItem('permissions');
      this._toastMessage.openSnackBarSuccess('You have been logged out');
      document.cookie = 'token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
      this.router.navigate(['/']);
    }

    showJoiningBonusList() {
      this.router.navigate(['/joiningBonus/list']);
    }

    showRejoiningBonusList() {
      this.router.navigate(['/rejoiningBonus/list']);
    }

    addCampaign() {
      this.router.navigate(['/campaign']);
    }

    getTableData() {
      this.dataSource = this.tableData;
      this.dataSource.paginator = this.paginator;
    }

    getDateFormatted(date) {
      if (date) {
        const today = new Date(parseFloat(date));
        let dd: any = today.getDate();
        const mm: any = today.getMonth() + 1;
        const yyyy: any = today.getFullYear().toString().substr(-2);
        if (dd < 10) {
          dd = '0' + dd;
        }
        const dateInfo = dd + ' ' + this.monthMap[mm] + ' \'' + yyyy;
        return dateInfo;
      } else {
        return 'N/A';
      }
    }

    setAccessRights() {
      this._accessRightsService.getUserAccessRights()
        .subscribe((res) => {
          this.accessRights = this.getAccessRights(res.data['data']);
          console.log('accessRights : ', this.accessRights);
          if (this.displayedColumns.includes('actions')) {
            if (this.accessRights && !this.accessRights.edit_campaign_access && !this.accessRights.campaign_performance) {
              const cols = this.displayedColumns.slice(0, -1);
              this.displayedColumns = cols;
            }
          }
        },
        err => {
          console.log('Unable to fetch user access rights ', err);
        });
    }

    fetchTableData(limit, offset, code, city, role, status) {
      this._campaignListPageService.getListData(limit, offset, code, city, role, status.value)
      .subscribe(res => {
        this.tableData = res.body.result.campaigns;
        this.noOfCampaigns = res.body.result && res.body.result.totalCount ? res.body.result.totalCount : 5;
        if (this.tableData.length < 1) {
          this._toastMessage.openSnackBarError('No campaigns found related to your search');
        }
        this.getTableData();
      },
      err => {
        console.log('An error occurred - ', err);
        this._toastMessage.openSnackBarError('Couldn\'t fetch data');
      });
    }

    sortData(sort: Sort) {
      this.paginator.firstPage();
    }

    getAccessRights(data: string[]) {
      let temp = {};

      data.includes('view_campaign_access') ? temp['view_campaign_access'] = true : temp['view_campaign_access'] = false;
      data.includes('edit_campaign_access') ? temp['edit_campaign_access'] = true : temp['edit_campaign_access'] = false;
      data.includes('campaign_performance') ? temp['campaign_performance'] = true : temp['campaign_performance'] = false;
      data.includes('view_referral_usage') ? temp['view_referral_usage'] = true : temp['view_referral_usage'] = false;
      data.includes('duplicate_campaign') ? temp['duplicate_campaign'] = true : temp['duplicate_campaign'] = false;
      data.includes('create_campaign') ? temp['create_campaign'] = true : temp['create_campaign'] = false;

      return temp;
    }

    pageChanged(evt: any) {
      this.limit = evt.pageSize;
      this.newPage = this.paginator.pageIndex;
      this.numberOfPages = Math.ceil(this.noOfCampaigns / this.limit);
      if (evt.pageIndex + 1 === this.numberOfPages) {
        this.offset = (evt.pageIndex) * this.limit;
      } else if (evt.pageIndex === 0) {
        this.offset = 0;
      } else {
        this.offset = this.newPage >= this.currentPage ? this.offset + this.limit : this.offset - this.limit;
      }
      this.fetchTableData(this.limit, this.offset, this.code, this.city, this.role, this.status);
      this.currentPage  = this.paginator.pageIndex;
    }

    goToPerformance(id, startDate, endDate) {
      this.router.navigate(['/performance'], {queryParams: {id, startDate, endDate}});
    }

    activate(id) {
      this._campaignListPageService.activate(id).subscribe(res => {
        if (res && res.body) {
          if (res.body.status && res.body.status === 'Success') {
            this._toastMessage.openSnackBarSuccess('Activated campaign');
            this.fetchTableData(this.limit, this.offset, this.code, this.city, this.role, this.status);
          } else {
            if (res.body.error && res.body.error.message) {
              this._toastMessage.openSnackBarError(res.body.error.message);
            }
          }
        }
      }, err => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this._toastMessage.openSnackBarError(err.error.error.message);
        } else {
          this._toastMessage.openSnackBarError('Something went wrong, please try again.');
        }
      });
    }

    deactivate(id) {
      this._campaignListPageService.deactivate(id).subscribe(res => {
        if (res && res.body) {
          if (res.body.status && res.body.status === 'Success') {
            this._toastMessage.openSnackBarSuccess('Deactivated campaign');
            this.fetchTableData(this.limit, this.offset, this.code, this.city, this.role, this.status);
          } else {
            if (res.body.error && res.body.error.message) {
              this._toastMessage.openSnackBarError(res.body.error.message);
            }
          }
        }
      }, err => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this._toastMessage.openSnackBarError(err.error.error.message);
        } else {
          this._toastMessage.openSnackBarError('Something went wrong, please try again.');
        }
      });
    }

    onEdit = (campaignId) => {
      this.router.navigate(['/campaign/edit/', campaignId]);
    }

    ngOnInit() {
      // if (!localStorage.getItem('userId')) {
      //   alert('Please login to access this page');
      //   this.router.navigate(['/']);
      // }
      this.currentPage = 0;
      this.screenBreakpoint = (window['jQuery'](window).innerWidth() <= 760) ? true : false;
      if (this.screenBreakpoint) {
        this.displayedColumns = ['position', 'code', 'role', 'actions'];
      } else {
        this.displayedColumns = ['position', 'code', 'role', 'vehicle', 'city', 'startDate', 'endDate', 'actions'];
      }
      this.setAccessRights();
      this.fetchTableData(this.limit, this.offset, this.code, this.city, this.role, this.status);
    }

    onResize(event) {
      this.screenBreakpoint = (event.target.innerWidth <= 760) ? true : false;
      if (this.screenBreakpoint) {
        if (this.displayedColumns.includes('actions')) {
          this.displayedColumns = ['position', 'code', 'role', 'actions'];
        } else {
          this.displayedColumns = ['position', 'code', 'role'];
        }
      } else {
        if (this.displayedColumns.includes('actions')) {
          this.displayedColumns = ['position', 'code', 'role', 'vehicle', 'city', 'startDate', 'endDate', 'actions'];
        } else {
          this.displayedColumns = ['position', 'code', 'role', 'vehicle', 'city', 'startDate', 'endDate']
        }
      }
    }

    openInfoCard(campaignId) {
      this.router.navigate(['/campaign/view/', campaignId]);
    }

    fetchDataToShow(data) {
        const dataArray = [];
        const finalData = { title: '', data: '' };
        finalData.title = 'Campaign Information';
        for (const key in data) {
            if (key && this.dataCardInfo[key]) {
                if (key === 'isActive') {
                    dataArray.push('Status : ' + (data[key] ? 'Active' : 'Not Active'));
                } else if (key === 'endDate' || key === 'startDate') {
                    dataArray.push(this.dataCardInfo[key] + ' : ' + (data[key] ? new Date(data[key]).toLocaleDateString() : 'N/A'));
                } else {
                    dataArray.push(this.dataCardInfo[key] + ' : ' + data[key] || 'N/A');
                }
            }
        }
        finalData.data = dataArray.join('\n');
        return finalData;
    }

    openDialog(data): void {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: 'fit-content',
        disableClose: true,
        hasBackdrop: true,
        data: {data}
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }

}
