import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastMessage } from '../toast-message/toast-message.service';
@Component({
  selector: 'coupons-page',
  template: require('./coupons-page.component.html')

})
export class CouponsPageComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches)
  );

  constructor(private router: Router, 
              private breakpointObserver: BreakpointObserver,
              private _toastMessage: ToastMessage) { }

  ngOnInit() {
  }
  goToCoupons() {
    this.router.navigate(['/coupons']);
  }
  goToReferrals() {
    this.router.navigate(['/referrals']);
  }
  logout(){
    document.cookie = " ";
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");

    this._toastMessage.openSnackBarSuccess("You have been logged out");
    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    this.router.navigate(['/']);
  }

}
