import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastMessage } from '../toast-message/toast-message.service';
import { RejoiningBonusCreatePageService } from './rejoining-bonus-create-page.service';

type ServiceDetail = {
  _id: string;
  name: string;
}
@Component({
  selector: 'rejoining-bonus-create-page',
  template: require('./rejoining-bonus-create-page.component.html'),
  providers: [ToastMessage, RejoiningBonusCreatePageService]
})

export class RejoiningBonusCreatePageComponent implements OnInit {
  cityList = [];
  cityMap = {};
  selectedCity: string = null;
  serviceList: string[] = [];
  selectedServices: string[] = [];
  selectedServiceDetails: ServiceDetail[] = [];
  servicesFetched = [];
  userSelectorList: string[] = [];
  userSelector: string = null;
  userSelectorsFetched = {};
  stepper: string[] = ['general'];
  filledForm: string[] = [];
  rejoiningBonusName: string;
  stepperValue = 0;
  rejoiningBonusData: any = {};
  rejoiningBonusOfferPage: boolean = false
  rejoiningBonusStartDate: string = null;
  minStartDate= new Date();

  ngOnInit() {
    this.getCities();
  }

  constructor(
    private router: Router,
    private _toastMessage: ToastMessage,
    private _rejoiningBonusCreatePageService: RejoiningBonusCreatePageService
  ) { }

  logout() {
    document.cookie = ' ';
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    this._toastMessage.openSnackBarSuccess('You have been logged out');
    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    this.router.navigate(['/']);
  }

  backToList() {
    this.router.navigate(['/rejoiningBonus/list']);
  }

  getCities() {
    this._rejoiningBonusCreatePageService.getCityList().subscribe(res => {
      const cityList = [];
      var cities = res.data.cities;
      for (const city in cities) {
        if (cities[city]) {
          const name = cities[city]['displayName'];
          this.cityMap[name] = cities[city]['_id'];
          cityList.push(name);
        }
      }
      this.cityList = cityList;
    }, () => {
      this._toastMessage.openSnackBarError('Unable to fetch cities.');
    });
  }

  cityChanged() {
    if (this.selectedCity == null) {
      this.userSelectorList = []
      this.userSelector = null
      this.serviceList = []
      this.selectedServices = []
    }
    this.fetchServices();
  }
  
  serviceChanged() {
    let serviceDetails: ServiceDetail[] = []
    this.servicesFetched.forEach((service) => {
      if (service) {
        const serviceName = service.service && service.service.name;
        if (this.selectedServices.includes(serviceName)) {
          serviceDetails.push({ _id: service._id, name: serviceName })
        }
      }
    })
    this.selectedServiceDetails = serviceDetails
    this.fetchUserSelectors();
  }

  fetchUserSelectors() {
    this.userSelectorList = [];
    if (this.selectedCity) {
      const cityId = this.cityMap[this.selectedCity];
      const serviceDetailIds = this.selectedServiceDetails.map(x => x._id)
      this._rejoiningBonusCreatePageService.getUserSelectorList(cityId, serviceDetailIds).subscribe(res => {
        const finalUserSelectors = [];
        res.data.selectors.forEach((userSelector) => {
          const userSelectorName = userSelector && userSelector.name;
          this.userSelectorsFetched[userSelectorName] = userSelector._id;
          finalUserSelectors.push(userSelectorName);
        })
        this.userSelectorList = finalUserSelectors;
      }, (err) => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this._toastMessage.openSnackBarError(err.error.error.message);
        } else {
          this._toastMessage.openSnackBarError('Unable to fetch user selectors');
        }
      });
    }
  }

  fetchServices() {
    this.serviceList = [];
    this.servicesFetched = [];
    if (this.selectedCity) {
      const cityId = this.cityMap[this.selectedCity];
      this._rejoiningBonusCreatePageService.getServicesList(cityId).subscribe(res => {
        this.servicesFetched.push(...res.data);
        const finalServices = [];
        for (const service in this.servicesFetched) {
          if (service) {
            const serviceName = this.servicesFetched[service] && this.servicesFetched[service]['service']
              && this.servicesFetched[service]['service']['name'];
            finalServices.push(serviceName);
          }
        }
        this.serviceList = finalServices;
      }, () => {
        this._toastMessage.openSnackBarError('Unable to fetch services.');
      });
    }
  }


  stepperIncrement(num) {
    this.stepperValue = num;
    if (this.stepperValue === 100) {
      this._toastMessage.openSnackBarSuccess('You\'re all done!');
    }
  }

  resetGeneralDetails() {
    this.rejoiningBonusName = null;
    this.selectedCity = null;
    this.userSelector = null;
  }

  onSubmitGenaralDetails = () => {
    this.rejoiningBonusData.name = this.rejoiningBonusName
    this.rejoiningBonusData.city = this.selectedCity
    this.rejoiningBonusData.userSelector = [{ _id: this.userSelectorsFetched[this.userSelector], name: this.userSelector }]
    this.rejoiningBonusData.services = this.selectedServiceDetails
    this.rejoiningBonusData.startDate = new Date(this.rejoiningBonusStartDate).getTime()


    this._rejoiningBonusCreatePageService.validate(this.rejoiningBonusData).subscribe(res => {
      this._toastMessage.openSnackBarSuccess('General details submitted');
      this.stepper = [];
      if (this.filledForm.indexOf('general') < 0) {
        this.filledForm.push('general');
      }
      this.stepperIncrement(20);
      this._toastMessage.openSnackBarSuccess('You can now click Expand All and Collapse All on the bottom right for this panel');
      this.rejoiningBonusOfferPage = true
    },
      (err) => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this._toastMessage.openSnackBarError(err.error.error.message);
        } else {
          this._toastMessage.openSnackBarError('Something went wrong, please try again.');
        }
      })
  }

  submitGeneralDetails() {
    if (!this.rejoiningBonusName || this.rejoiningBonusName.trim().length === 0) {
      this._toastMessage.openSnackBarError('Please enter a name for the re-joining bonus');
      return
    }
    if (this.selectedCity === null) {
      this._toastMessage.openSnackBarError('Please select the city that this re-joining bonus will run in');
      return
    }
    if (this.selectedServices.length === 0) {
      this._toastMessage.openSnackBarError('Please select the service that this re-joining bonus will apply for');
      return
    }
    if (!this.userSelector) {
      this._toastMessage.openSnackBarError('Please select the user selector that this re-joining bonus will apply for');
      return
    }
    if (!this.rejoiningBonusStartDate) {
      this._toastMessage.openSnackBarError('Please select the rejoining bonus start date');
      return
    }
    var today = new Date();
    today.setHours(0,0,0,0);
    if(new Date(this.rejoiningBonusStartDate) < today){
      this._toastMessage.openSnackBarError('Rejoining bonus start date cant be old date.');
    }
    this.rejoiningBonusName = this.rejoiningBonusName.trim();
    this.onSubmitGenaralDetails();
  }

  expansionPanelOpen(evt, stage) {
    if (this.stepper.indexOf(stage) < 0) {
      this.stepper.push(stage);
    }
  }

  expansionPanelClose(evt, stage) {
    if (this.stepper.indexOf(stage) > -1) {
      this.stepper.splice(this.stepper.indexOf(stage), 1);
    }
  }

  expandAll() {
    this.stepper = ['type', 'general'];
  }

  collapseAll() {
    this.stepper = [];
  }
}
