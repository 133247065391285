import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'

@Injectable()
export class JoiningBonusCreatePageService {
  validateJoiningBonus = environment.SERVER + '/api/joiningBonus/validate';
  getServicesURL = environment.SERVER + '/api/get/services';
  getCitiesUrl = environment.SERVER + '/api/get/cities';

  constructor(private http: HttpClient) { }

  getCityList() {
    return this.http.get<any>(this.getCitiesUrl);
  }

  getServicesList(city) {
    const payload = { 'city': city };
    return this.http.post<any>(this.getServicesURL, payload);
  }

  validate(data) {
    const payload = {
      'body': data,
    };
    return this.http.post<any>(this.validateJoiningBonus, payload);
  }
}